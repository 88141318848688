import React from 'react'
import { useSearchParams } from 'react-router-dom';
import Home from '../components/Dashboards/Seller/Home'
import Store from '../components/Dashboards/Seller/Store'
import Products from '../components/Dashboards/Seller/Products'
import Marketing from '../components/Dashboards/Seller/Marketing'
import Analytics from '../components/Dashboards/Seller/Analytics'

function DashboardPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      {!searchParams.get("page") && (
        <Home />
      )}
      {searchParams.get("page") && searchParams.get("page") === "store" && (
        <Store />
      )}
      {searchParams.get("page") && searchParams.get("page") === "products" && (
        <Products />
      )}
      {searchParams.get("page") && searchParams.get("page") === "marketing" && (
        <Marketing />
      )}
      {searchParams.get("page") && searchParams.get("page") === "analytics" && (
        <Analytics />
      )}
    </div>
  )
}

export default DashboardPage