import React from 'react'
import { PRODUCT_THUMBNAIL_UPLOADS_URL } from '../../configs/api/api.config'

function Product({ product, theme_color }) {
    const styles = {
        theme_bg_border: {
            backgroundColor: (theme_color + '10'),
            borderColor: theme_color
        }
    }

  return (
    <a href={"?product=" + product.product_id}>
        <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2' style={styles.theme_bg_border}>
          <div className="flex flex-row m-3 h-auto">
            <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + product.thumbnail} alt="product thumbnail" className='h-[100px] w-[100px] object-cover rounded' />
        
            <div className="ml-2 text-left w-full">
              <p className="text-lg">{product.title}</p>
              <p className="font-light">{product.description}</p>
            </div>
            <div className="float-right text-right mr-2">
              <table className="h-full">
                <tbody className="h-full">
                  <tr className="h-full">
                    <td className="align-middle">
                      <p className="align-middle inline-block">${(product.price / 100)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </a>
  )
}

export default Product