import React from 'react'

import {hero_headline, hero_subheading, hero_call_to_action, hero_call_to_action_page} from '../../configs/home/Hero.config'
// import hero_1 from "../../assets/svg/hero_1.svg"
// import hero_2 from "../../assets/svg/hero_2.svg"
import hero_bg from "../../assets/images/hero_bg.jpg"

function Hero() {
  return (
    <div className="bg-black bg-no-repeat bg-cover" style={{ backgroundImage: `url(${hero_bg})`}}>
        <div className="mx-auto pt-52 pb-56 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl text-white">{hero_headline}</p>
            <p className="text-lg mt-4 text-white">{hero_subheading}</p>
            
            <a href={hero_call_to_action_page} className="">
                <div className="bg-accent rounded-3xl w-fit mx-auto mt-10">
                    <p className='text-lg font-medium text-white py-2 px-6'>                    
                        {hero_call_to_action}
                    </p>
                </div>
            </a>
        </div>

        {/* <div className="flex flex-row justify-between">
             <img src={hero_1} className="mt-20 w-96" alt="hero 1 svg" /> 
            <img src={hero_2} className="-mt-36 mr-10 w-96 transparancy-" alt="hero 2 svg" />
        </div> */}
    </div>
  )
}

export default Hero