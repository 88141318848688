import React from 'react'

import  RoShop from '../../assets/images/RoShop.png'
import { PRODUCT_THUMBNAIL_UPLOADS_URL } from '../../configs/api/api.config'

function ShoppingCartItem({ getItems, product_id, thumbnail, title, storename, price }) {
    function removeItem(){
        const cart = sessionStorage.getItem("cart").split(',').map(Number)
        sessionStorage.setItem("cart", cart.filter(item => item !== product_id))
        getItems()
    }

  return (
    <div className='flex flex-row justify-between py-3 border-b w-full border-accent items-center'>
        <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + thumbnail} className='h-20 w-20 aspect-square object-contain ml-6 xl:mr-2' alt="" />
        <div className="h-full w-1/3 my-auto">
            <div className="h-full flex items-center">
                <div className="">
                    <p className='font-semibold my-auto text-lg'>{title}</p>
                    <p className='text-sm my-auto'>By : {storename}</p>
                </div>
            </div>
        </div>
        <div className='flex flex-row w-2/5 mr-10 h-full'>
            <div className="w-11/12 flex flex-row">
                <p className='w-1/3 my-auto text-center'>${price / 100}</p>
                <p className='w-1/3 my-auto text-center'>1</p>
                <p className='w-1/3 my-auto text-center'>${price / 100}</p>
            </div>

            <div className="w-1/12 h-full flex items-center justify-center">
                <button onClick={removeItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='' width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>

        </div>
    </div>
  )
}

export default ShoppingCartItem