import React from "react";
function Success(props) {
    const changeMessageState = () => {
        props.changeMessage("")
    }

    return (
        <div className="w-full">
            <div className="w-full bg-orange-300 text-white">
                <div className="container mx-auto">
                    <p className="py-3" dangerouslySetInnerHTML={{__html: props.message}}></p>
                    <button type="button" data-bs-dismiss="alert" className="btn-close " aria-label="Close" style={{float: 'right'}} onClick={changeMessageState}/>
                </div>
            </div>
        </div>   
    );
}

export default Success;