import React from 'react'
import { PRODUCT_THUMBNAIL_UPLOADS_URL } from '../../configs/api/api.config'

function Item({item}) {
  console.log(item)
  return (
    <a href={"/s/"+ item.name +"?product=" + item.product_id}>
      <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
        <div className="flex flex-row m-3 h-auto">
          <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + item.thumbnail} alt="" className='h-[100px] w-[100px] object-cover' />
      
          <div className="ml-2 text-left w-full">
            <p className="text-lg">{item.title}</p>
            <p className="font-light">{item.description}</p>
          </div>
          <div className="float-right text-right mr-2">
            <table className="h-full">
              <tbody className="h-full">
                <tr className="h-full">
                  <td className="align-middle">
                    <p className="align-middle inline-block">${(item.price / 100)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </a>
  )
}

export default Item