import React from 'react'

function Footer() {
  return (
    <div className="">
        {/* wave */}
        <svg className='mt-56' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 255">
            <path fill="#f1f5f9" fillOpacity="1" d="M0,160L48,181.3C96,203,192,245,288,245.3C384,245,480,203,576,165.3C672,128,768,96,864,96C960,96,1056,128,1152,149.3C1248,171,1344,181,1392,186.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>

        {/* footer */}
        <div className="bg-slate-100 w-full min-h-[400px]">
            <div id="header_logo" className='text-center p-5'>
                <p className="text-2xl md:text-3xl font-black"><span className="text-accent">Ro</span>Shop</p>
            
                <div className="w-4/5 mx-auto">
                    <hr className='my-10 border-accent/5 border-2' />
                </div>

                <div className="md:grid md:grid-cols-3">
                    <div id="services">
                        <p className="text-black text-center text-lg font-semibold">Documentation</p>
                        <p className="text-black text-center">
                            Sellers
                        </p>
                        <p className="text-black text-center">
                            Buyers
                        </p>
                    </div>
                    <div id="company" className='mt-6 md:mt-0'>
                        <p className="text-black text-center text-lg font-semibold">Company</p>
                        <a href="https://docs.google.com/document/d/1oq10rwTrF76Mt2kHXJAGeBOw567PBVrCnUC8GdJ-AV8/edit?usp=sharing">
                            <p className="text-black text-center">Careers</p>
                        </a>
                        <a href="">
                            <p className="text-black text-center">Privacy Policy</p>
                        </a>
                        <a href="">
                            <p className="text-black text-center">Terms of Service</p>
                        </a>
                    </div>
                    <div id="connect" className='mt-6 md:mt-0'>
                        <p className="text-black text-center text-lg font-semibold">Connect</p>
                        <a href="mailto: info@roshop.dev">
                            <p className="text-black text-center">Email</p>
                        </a>
                        <a href="https://discord.gg/YzJzSfDjzf" target="_blank" rel="noreferrer">
                            <p className="text-black text-center">Discord</p>
                        </a>
                    </div>
                </div>

                <div className="w-2/5 mx-auto">
                    <hr className='my-10 border-accent/5 border-2' />
                </div>

                <p className="text-lg font-black"><span className="text-accent">Ro</span>Shop <span className='text-base font-normal'>is not affiliated with the Roblox Corporation</span></p>
            </div>
        </div>        
    </div>
  )
}

export default Footer