import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';

import ShoppingCartItem from '../components/checkout/ShoppingCartItem'
import Header from '../components/Header'

import { useStripe, Elements, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { PAYMENTS_URL } from '../configs/api/api.config';
import CheckoutForm from '../components/checkout/CheckoutForm';
import Cookies from 'universal-cookie';

const stripePromise = loadStripe('pk_test_51LIdHtH3QBEiJL1npXaCwfiTndcpEaFoCRA1hSt9uFC20m5rih6ob1yx1PLEBvEbQAeOs4qM7Ng3JsQajZn3pdBb002Y0nIYHg', { locale: 'en' });

const newCookies = new Cookies()

function Checkout() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [errorMessage, setErrorMessage] = useState(null);
    const [products, setProducts] = useState(sessionStorage.getItem('cart'));
    const [productsInfo, setProductsInfo] = useState();
    const [productsPrice, setProductsPrice] = useState();
    const [referralDiscount, setReferralDiscount] = useState();

    function getItems(){
        console.log("started")
        if(sessionStorage.getItem('cart')){
            const user = getCookie()  
    
            axios.get(PAYMENTS_URL + "cart/items?products=" + sessionStorage.getItem('cart'), {
                headers: {
                'x-access-token': user
                }
            })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success){
                        setProductsInfo(response.data.data.products)
                        setProductsPrice(response.data.data.total_price)
                        setReferralDiscount(response.data.data.referral_discount)
                    } else {
                        console.log(response.data.message)
                    }
                })
        } else {
            console.log("No products")
            setProductsInfo([])
            setProductsPrice(0)
            setReferralDiscount(0)
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    // useEffect(() => {
    //     function checkData() {
    //         const item = localStorage.getItem('cart')
        
    //         if (item) {
    //             console.log(item)
    //             setProducts(item)
    //         }
    //     }
      
    //     window.addEventListener('storage', checkData)
      
    //     return () => {
    //         window.removeEventListener('storage', checkData)
    //     }
    // }, [])
    

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
    }  

    if(searchParams.get('client_secret')){
        const options = {
                clientSecret: searchParams.get('client_secret'),
                appearance: {
                    
                },
        };

        return (
            <div className="">
                <Header />

                <div className='container mx-auto pt-10 md:pt-16 lg:pt-20'>
                    <p className="font-extrabold text-xl md:text-2xl lg:text-3xl text-center">Checkout</p>

                    <div className="flex flex-row pt-20">
                        <div className='flex flex-col col-span-2 w-3/4 border-2 border-b rounded-xl border-accent h-fit'>
                            <div className='border-b-2 border-accent'>
                                <div className='flex flex-row justify-between pb-3 pt-3'>
                                    <p className='ml-6'>Product</p>
                                    <div className='flex flex-row w-2/5 mr-10 h-full'>
                                        <div className="w-11/12 flex flex-row">
                                            <p className='w-1/3 my-auto text-center'>Price</p>
                                            <p className='w-1/3 my-auto text-center'>Quantity</p>
                                            <p className='w-1/3 my-auto text-center'>Subtotal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { productsInfo && productsInfo.map((product, key) => {
                                return <ShoppingCartItem key={key} getItems={getItems} product_id={product.product_id} thumbnail={product.thumbnail} title={product.title} storename={product.name} price={product.price} />
                            })}
                        
                        </div>

                        <div className='inline align-center border-2 border-accent rounded-xl ml-12 p-4 h-fit'>
                            <div className='flex flex-col w-full'>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Subtotal</p>
                                    <p className='text-right'>${productsPrice / 100}</p>
                                </div>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Payment&nbsp;processing&nbsp;fee</p>
                                    <p className='text-right'>$0.30</p>
                                </div>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Discount</p>
                                    <p className='text-right'>${referralDiscount / 100}</p>
                                </div>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p className='font-bold'>Total</p>
                                    <p className='text-right font-bold'>${(productsPrice + 30 - referralDiscount) / 100}</p>
                                </div>

                                <p className="text-xl font-bold mt-6 mb-4">Please fill in your details to continue</p>
                            
                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm order_id={searchParams.get('order_id')} />
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );        
    } else {
        function proceedPayment(){
            const user = getCookie()

            if(sessionStorage.getItem('cart') && sessionStorage.getItem('cart').length > 0){
                axios.get(PAYMENTS_URL + "cart/start?products=" + sessionStorage.getItem('cart'), {
                    headers: {
                      'x-access-token': user
                    }
                })
                    .then((response) => {
                        if(response.data.success){
                            setSearchParams({client_secret: response.data.data.client_secret, order_id: response.data.data.order_id})
                        } else {
    
                        }
                    })
            } else {
                console.log("No items in cart.")
            }
        }

        return (
            <div className="">
                <Header />

                <div className='container mx-auto pt-10 md:pt-16 lg:pt-20'>
                    <p className="font-extrabold text-xl md:text-2xl lg:text-3xl text-center">Checkout</p>

                    <div className="flex flex-row pt-20">
                        <div className='flex flex-col col-span-2 w-3/4 border-2 border-b rounded-xl border-accent h-fit'>
                            <div className='border-b-2 border-accent'>
                                <div className='flex flex-row justify-between pb-3 pt-3'>
                                    <p className='ml-6'>Product</p>
                                    <div className='flex flex-row w-2/5 mr-10 h-full'>
                                        <div className="w-11/12 flex flex-row">
                                            <p className='w-1/3 my-auto text-center'>Price</p>
                                            <p className='w-1/3 my-auto text-center'>Quantity</p>
                                            <p className='w-1/3 my-auto text-center'>Subtotal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { productsInfo && productsInfo.map((product, key) => {
                                return <ShoppingCartItem key={key} getItems={getItems} product_id={product.product_id} thumbnail={product.thumbnail} title={product.title} storename={product.name} price={product.price} />
                            })}
                        
                        </div>
                        <div className='inline align-center border-2 border-accent rounded-xl ml-12 p-4 h-fit'>
                            <div className='flex flex-col w-full'>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Subtotal</p>
                                    <p className='text-right'>${productsPrice / 100}</p>
                                </div>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Payment&nbsp;processing&nbsp;fee</p>
                                    <p className='text-right'>$0.30</p>
                                </div>

                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p>Discount</p>
                                    <p className='text-right'>${referralDiscount / 100}</p>
                                </div>
                                <div className='grid grid-flow-row-dense grid-cols-2 py-2'>
                                    <p className='font-bold'>Total</p>
                                    <p className='text-right font-bold'>${(productsPrice + 30 - referralDiscount) / 100}</p>
                                </div>
                                <button onClick={proceedPayment} className='bg-accent text-white text-center font-semibold w-full rounded-xl py-2 mt-6'>Proceed&nbsp;to&nbsp;checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Checkout