import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import NotFoundSVG from "../assets/svg/not_found.svg"
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'



function NotFound() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
      <Header user={user} role={role} />


        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
          <img src={NotFoundSVG} className="pb-8 max-h-[500px] mx-auto" alt="under construction" />

          <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">404 Page Not Found</p>
          <p className="text-lg mt-4 w-5/6 md:w-2/3 lg:w-2/5 mx-auto">looks like this page doesn't exist, try searching again.</p>
        </div>

        <Footer />
    </div>
  )
}

export default NotFound