import axios from 'axios';
import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PRODUCTS_URL } from '../../../../configs/api/api.config';

function EditProduct({ store_id, product, changeIsPopupOpen }) {
    const newCookies = new Cookies()
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(0)

    const [error, setError] = useState("")
    const [warning, setWarning] = useState("")

    const [nameProduct, setNameProduct] = useState()
    const [descriptionProduct, setDescriptionProduct] = useState()
    const [priceProduct, setPriceProduct] = useState()
    const [thumbnailProduct, setThumbnailProduct] = useState()
    const [assetProduct, setAssetProduct] = useState()

    useEffect(() => {
        if(product){
            setNameProduct(product.title)
            setDescriptionProduct(product.description)
            setPriceProduct(product.price / 100)
            setThumbnailProduct()
            setAssetProduct()
        }
    }, [product])

    function handleClose(){
        setSearchParams({page: "products", keywords: searchParams.get("keywords")})
        changeIsPopupOpen(false)
    }

    function handleNameInput(e){
        const value = e.target.value

        setNameProduct(value)
    }

    function handleDescriptionInput(e){
        const value = e.target.value

        setDescriptionProduct(value)
    }

    function handlePriceInput(e){
        const value = e.target.value

        if(value >= 0.5){
            setPriceProduct(value)
        } else {
            setPriceProduct(value)
            setWarning("The price should be minimum $0.50")
            setError("")
        }
    }

    function handleThumbnailInput(e){
        const value = e.target.files[0]

        setThumbnailProduct(value)
    }

    function handleAssetInput(e){
        const value = e.target.files[0]

        setAssetProduct(value)
    }

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
    }  

    function handleNext(){
        if(page === 0){
            console.log(nameProduct, descriptionProduct, priceProduct)
            if(nameProduct && descriptionProduct && priceProduct && priceProduct >= 0.5){
                setPage(1)
                setError("")
                setWarning("")
            } else {
                setError("Fill the fields in correctly first.")
                setWarning("")
            }
        } else {
            console.log(assetProduct)
            if(thumbnailProduct){
                const formData = new FormData()        
		        formData.append('thumbnail', thumbnailProduct)
                
                const user = getCookie()

                axios.patch(PRODUCTS_URL + "/thumbnail?store_id=" + store_id, formData, {
                    headers: {
                        "x-access-token": user,
                        'Content-Type': "application/json"
                    }
                })
                    .then((response) => {
                        console.log(response)

                        if(response.data.success){
                            handleClose()
                        } else {
                            setError(response.data.message)
                        }
                    })

                setError("")
                setWarning("")
            } 
            if(assetProduct){
                const formData = new FormData()        
		        formData.append('robloxAsset', assetProduct)
                
                const user = getCookie()

                axios.patch(PRODUCTS_URL + "/asset?store_id=" + store_id, formData, {
                    headers: {
                        "x-access-token": user,
                        'Content-Type': "application/json"
                    }
                })
                    .then((response) => {
                        console.log(response)

                        if(response.data.success){
                            handleClose()
                        } else {
                            setError(response.data.message)
                        }
                    })

                setError("")
                setWarning("")
            } 
            if(nameProduct && descriptionProduct && priceProduct){                
                const user = getCookie()

                axios.patch(PRODUCTS_URL + "/?store_id=" + store_id, {
                    product_id: product.product_id,
                    title: nameProduct,
                    description: descriptionProduct,
                    price: priceProduct
                }, {
                    headers: {
                        "x-access-token": user
                    }
                })
                    .then((response) => {
                        console.log(response)

                        if(response.data.success){
                            handleClose(false)
                        } else {
                            setError(response.data.message)
                        }
                    })

                setError("")
                setWarning("")
            } 
        }
    }

  return (        
    product && (
        <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
            <div className="w-full md:w-3/5 xl:w-2/5 h-2/3 md:h-1/2 mt-32 rounded-xl bg-white m-auto z-50">
                <div className="flex flex-row">
                    <div className="w-1/12"></div>

                    <p className="mx-auto pt-6 text-center font-bold text-2xl">Edit Product</p>

                    <button onClick={(e) => {handleClose(false)}} className="w-1/12 pt-5 text-center font-bold text-2xl">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                    </button>
                </div>

                {page === 0 && (
                    <div className="w-2/3 mx-auto">
                        <div className="text-left mt-5">
                            <p className="ml-6 mb-1">Name</p>
                            <input onChange={handleNameInput} type="text" name="" id='' placeholder={product.title} className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                        </div>
                        <div className="text-left mt-5">
                            <p className="ml-6 mb-1">Description</p>
                            <textarea onChange={handleDescriptionInput} name="" id='' placeholder={product.description} className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                        </div>
                        <div className="text-left mt-5">
                            <p className="ml-6 mb-1">Price</p>
                            <input onChange={handlePriceInput} type="text" name="" id='' placeholder={"$" + product.price / 100} className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                        </div>


                        <div className="text-left mt-5">
                            <button onClick={handleNext} className="flex flex-row px-6 w-full py-2 bg-accent text-white rounded-full">
                                <table className="w-full">
                                    <tbody className="w-full">
                                        <tr className="w-full">
                                            <td className="w-full">
                                                <p className="text-center font-medium">NEXT</p>
                                            </td>
                                            <td className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </button>
                            {error && (<p className="text-sm text-red-500 ml-6 mt-1">{error}</p>)}
                            {warning && (<p className="text-sm text-orange-300 ml-6 mt-1">{warning}</p>)}
                        </div>
                    </div>
                )}

                {page === 1 && (
                    <div className="w-2/3 mx-auto">
                        <div className="text-left mt-5">
                            <p className="mb-1">Thumbnail</p>
                            <input onChange={handleThumbnailInput} className="block w-full text-lg text-black bg-white rounded-lg border border-accent placeholder-accent cursor-pointer focus:outline-none " type="file" />
                            <p className="mt-1 text-sm text-gray-500">Find more info about <a href="/info-thumbnails"><span className='text-accent underline underline-offset-2'>Thumbnails</span></a>.</p>
                        </div>
                        <div className="text-left mt-5">
                            <p className="mb-1">Asset File</p>
                            <input onChange={handleAssetInput} className="block w-full text-lg text-black bg-white rounded-lg border border-accent placeholder-accent cursor-pointer focus:outline-none " type="file" />
                            <p className="mt-1 text-sm text-gray-500">Find more info about <a href="/info-assets"><span className='text-accent underline underline-offset-2'>Assets</span></a>.</p>
                        </div>

                        <div className="text-left mt-5">
                            <button onClick={handleNext} className="flex flex-row px-6 w-full py-2 bg-accent text-white rounded-full">
                                <table className="w-full">
                                    <tbody className="w-full">
                                        <tr className="w-full">
                                            <td className="w-full">
                                                <p className="text-center font-medium">NEXT</p>
                                            </td>
                                            <td className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </button>
                            {error && (<p className="text-sm text-red-500 ml-6 mt-1">{error}</p>)}
                            {warning && (<p className="text-sm text-orange-300 ml-6 mt-1">{warning}</p>)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
  )
}

export default EditProduct