import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Home/Hero'
import Steps from '../components/Home/Steps'
import Features from '../components/Home/Features'
import GetStarted from '../components/Home/GetStarted'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import Register from '../components/popups/Register'


function App() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    const referal = searchParams.get("ref")

    if(referal){
      if(!newCookies.get("ref")){
        newCookies.set("ref", referal, { path: '/' })
      }
    }
  })

  return (
    <div className=''>
      <Header user={user} role={role} />

      <Hero />
      <Steps />
      <Features />
      <GetStarted />

      <Footer />
    </div>
  )
}

export default App