import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL, USERS_URL } from '../../../configs/api/api.config'
import Error from '../../states/ErrorDashboard'
import Header from './Header'
// import Products from './pages/Products'
// import Transactions from './pages/Transactions'
// import Referrals from './pages/Referrals'
import SideMenu from './SideMenu'
import ComingSoon from '../../../pages/ComingSoon'

function Dashboard() {  
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [role, setRole] = useState("login")

  const [page, setPage] = useState("products")

  const [error, setError] = useState("")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)

            axios.get(USERS_URL, {
              headers: {
                'x-access-token': user
              }
            })
              .then((response) => {
                if(response.data.success){
                  setUserInfo(response.data.data[0]) 
                } else {
                  console.log("User info could not be retrieved.")
                }
              })
          } else {
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }     
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <ComingSoon />
  )
  
  // return (
  //   user && (
  //     <div className='min-h-screen'>
  //       <div className="fixed bg-white w-full">
  //         <Header user={user} role={role} user_info={userInfo} changeError={setError} />
  //         <SideMenu page={"Marketing"} changePage={setPage} />
  //       </div>
       

  //       <div className="flex flex-row pt-16">
  //         <div className="md:w-1/4 lg:w-1/5" />
          
  //         {/* Main content of the page */}
  //         <div className="w-full md:w-3/4 lg:w-4/5 ">
  //           {error && (<Error message={error} changeMessage={setError} />)}
  //           <div className="w-5/6 mx-auto mt-10 h-full">
  //             {/* { page === "products" && <Products user={user} role={role} user_info={userInfo} setError={setError} />}
  //             { page === "transactions" && <Transactions user={user} role={role} user_info={userInfo} setError={setError} />}
  //             { page === "referrals" && <Referrals user={user} role={role} user_info={userInfo} setError={setError} />} */}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // )
}

export default Dashboard