import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL, STORES_URL, USERS_URL } from '../../../configs/api/api.config'
import Error from '../../states/ErrorDashboard'
import Header from './Header'
// import Products from './pages/Products'
// import Transactions from './pages/Transactions'
// import Referrals from './pages/Referrals'
import SideMenu from './SideMenu'

import { Chart, registerables } from 'chart.js'
import CreateStore from './CreateStore'
import scrollToTop from '../../../hooks/scrollToTop'
Chart.register(...registerables)

function Dashboard(props) {  
  const newCookies = new Cookies()
  const { store } = useParams()
  const navigate = useNavigate()

  const ctx = 'weekSalesChart'
  const date = new Date()

  const [user, setUser] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [role, setRole] = useState("login")

  const [error, setError] = useState("")

  const [totalProducts, setTotalProducts] = useState(0)

  const last = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
  const day =last.getDate();
  const month=last.getMonth()+1;
  const year=last.getFullYear();

  const sevenDaysAgo = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getFullYear() 
  const sixDaysAgo = new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getFullYear() 
  const fiveDaysAgo = new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getFullYear()   
  const fourDaysAgo = new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getFullYear()   
  const threeDaysAgo = new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getFullYear()    
  const twoDaysAgo = new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getFullYear()   
  const oneDaysAgo = new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getFullYear()   

  const [datesLast7Days, setDatesLast7Days] = useState({sevenDaysAgo, sixDaysAgo, fiveDaysAgo, fourDaysAgo, threeDaysAgo, twoDaysAgo, oneDaysAgo})

  const [salesToday, setSalesToday] = useState()
  const [ordersToday, setOrdersToday] = useState()
  const [last7DaysOrders, setLast7DaysOrders] = useState([])
  const [last7DaysSales, setLast7DaysSales] = useState([])

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)

            axios.get(USERS_URL, {
              headers: {
                'x-access-token': user
              }
            })
              .then((response) => {
                if(response.data.success){
                  setUserInfo(response.data.data[0]) 
                } else {
                  console.log("User info could not be retrieved.")
                }
              })
          } else {
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }     
  }

  function getStore(){
    const user = getCookie()
    axios.get(STORES_URL + 'user?store=' + store, {
      headers: {
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setTotalProducts(response.data.data.products.length)
          getStoreAnalytics(response.data.data.store[0].store_id)
        } 
      })
  }

  function getStoreAnalytics(store_id){
    const user = getCookie()

    axios.get(STORES_URL + "analytics?store_id=" + store_id + "&begin_date=" + new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getFullYear()  + "-" + (new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).getDate() + "&end_date=" + new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getFullYear()  + "-" + (new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getDate() + "&current_date=" + new Date(date.getTime()).getFullYear()  + "-" + (new Date(date.getTime()).getMonth() + 1) + "-" + new Date(date.getTime()).getDate(), {
      headers: {
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setSalesToday(response.data.data.today_sales)
          setOrdersToday(response.data.data.today_orders)

          setLast7DaysSales([response.data.data.sales_last_7_days[0], response.data.data.sales_last_7_days[1], response.data.data.sales_last_7_days[2], response.data.data.sales_last_7_days[3], response.data.data.sales_last_7_days[4], response.data.data.sales_last_7_days[5], response.data.data.sales_last_7_days[6]])
          setLast7DaysOrders([response.data.data.orders_last_7_days[0], response.data.data.orders_last_7_days[1], response.data.data.orders_last_7_days[2], response.data.data.orders_last_7_days[3], response.data.data.orders_last_7_days[4], response.data.data.orders_last_7_days[5], response.data.data.orders_last_7_days[6]])
        } else {
          console.log(response.data.message)
          setError("Cannot load the last 7 days graph: " + response.data.message)
          scrollToTop()
        }
      })
  }
  
  useEffect(() => {
    if(document.getElementById(ctx) && last7DaysSales.length > 0 && last7DaysOrders.length > 0){
      if(!Chart.getChart(ctx)){
        const myChart = new Chart(ctx, {
          type: 'line',
          data: {
              labels: [datesLast7Days.sevenDaysAgo, datesLast7Days.sixDaysAgo, datesLast7Days.fiveDaysAgo, datesLast7Days.fourDaysAgo, datesLast7Days.threeDaysAgo, datesLast7Days.twoDaysAgo, datesLast7Days.oneDaysAgo],
              datasets: [{
                  label: 'Revenue',
                  data: [last7DaysSales[0]/100, last7DaysSales[1]/100, last7DaysSales[2]/100, last7DaysSales[3]/100, last7DaysSales[4]/100, last7DaysSales[5]/100, last7DaysSales[6]/100],
                  backgroundColor: 'rgba(87, 105, 171, 100)',
                  borderColor: 'rgba(87, 105, 171, 100)',
                  borderWidth: 1
              },{
                label: 'Orders',
                data: [last7DaysOrders[0], last7DaysOrders[1], last7DaysOrders[2], last7DaysOrders[3], last7DaysOrders[4], last7DaysOrders[5], last7DaysOrders[6]],
                backgroundColor: 'rgba(87, 105, 171, 50)',
                borderColor: 'rgba(87, 105, 171, 50)',
                borderWidth: 1
            },]
          },
          options: {
              scales: {
                  y: {
                      beginAtZero: true
                  }
              }
          }
        })
      }
    }


  }, [document.getElementById(ctx), last7DaysSales])

  useEffect(() => {
    getUser()
    if(store !== 'create'){
      getStore()
    }
  }, [store])

  if(store !== "create"){
    return (
      user && (
        <div className='min-h-screen'>
          <div className="fixed bg-white w-full">
            <Header user={user} role={role} user_info={userInfo} changeError={setError} />
          </div>
        

          <div className="flex flex-row pt-16">
            
            {/* Main content of the page */}
            <div className="container mx-auto pt-14">
              {error && (<Error message={error} changeMessage={setError} />)}

              <p className="text-2xl font-bold">Welcome <span className="underline underline-offset-2 decoration-accent">{userInfo && userInfo.username}</span>{!userInfo && "to your store"}!</p>

              {totalProducts === 0 && (
                <div id="get-started" className="mt-8">
                  <div className="border border-accent px-4 py-2 rounded-xl">
                    <p className="text-sm uppercase text-accent">Getting started</p>
                    <p className="mt-1">Start by <a href={"?page=products&action=create"}><span className="text-accent underline underline-offset-2">creating your first product</span></a>, once you created your product listing you can start selling your product.</p>
                  
                    <p className="text-sm mt-2">Find more about <a href="/documentation-on-how-to-get-started"><span className="text-accent underline">how to get started</span></a>.</p>
                  </div>
                </div>
              )}

              <div className="mt-8">
                <p className="text-lg font-semibold">Today</p>

                <div className="flex flex-row mt-2">
                  <div className="border border-accent px-4 py-2 w-full mr-4 rounded-xl uppercase">
                    <p className="text-sm text-accent">Orders</p>
                    <p className="text-xl font-semibold text-left">{ordersToday}</p>
                  </div>
                  <div className="border border-accent px-4 py-2 w-full mr-4 rounded-xl uppercase">
                    <p className="text-sm text-accent">Sales</p>
                    <p className="text-xl font-semibold text-left">${salesToday/100}</p>
                  </div>
                  {/* <div className="border border-accent px-4 py-2 w-full ml-4 rounded-xl uppercase">
                    <p className="text-sm text-accent">Store visits</p>
                    <p className="text-xl font-semibold text-left">0</p>
                  </div> */}
                </div>
              </div>

              <div className="mt-8 mb-8">
                {/* {day + "-" + month + "-" + year} */}
                <p className="text-lg font-semibold">Last 7 days</p>
                <canvas id="weekSalesChart" className='w-full max-h-[500px]'></canvas>
              </div>
            </div>
          </div>
        </div>
      )
    )
  } else {
    return (
      <CreateStore />
    )
  }
}

export default Dashboard