import React, { useEffect } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { heading, sub_heading, display_monthly_yearly_choice, pricing_plans, features } from '../configs/Pricing_Page.config'
import { useState } from 'react'
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'


function Features() {
  const [selectedTime, setSelectedTime] = useState("monthly")

  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
      <Header user={user} role={role} />



        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
          <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">{heading}</p>
          <p className="text-lg mt-4 w-3/4 md:w-1/2 lg:w-1/3 mx-auto">{sub_heading}</p>
        
          {display_monthly_yearly_choice && (
            <div className="">
              <p className="font-medium text-lg text-center pt-32">How often do you want to pay?</p>
              <div className="bg-accent/20 flex flex-row h-12 w-2/3 md:w-1/3 lg:w-1/6 mx-auto m-4 rounded-full">
                <table className="h-full w-full">
                  <tbody className="h-full w-full">
                    <tr className="h-full w-full">
                      {selectedTime === "monthly" && (
                          <td className="h-full w-1/2 align-middle bg-accent text-white rounded-full">
                            <button onClick={() => { setSelectedTime("monthly") }} className="h-full w-full">
                              <p className="text-center font-bold uppercase">
                                monthly
                              </p>
                            </button>
                          </td>
                      )}
                      {selectedTime === "monthly" && (
                          <td className="h-full w-1/2 bg-transparent text-accent rounded-full">
                            <button onClick={() => { setSelectedTime("yearly") }} className="h-full w-full">
                              <p className="text-center font-bold uppercase">
                                yearly
                              </p>
                            </button>
                          </td>
                      )}
                      {selectedTime === "yearly" && (
                          <td className="h-full w-1/2 align-middle bg-transparent text-accent rounded-full">
                            <button onClick={() => { setSelectedTime("monthly") }} className="h-full w-full">
                              <p className="text-center font-bold uppercase">
                                monthly
                              </p>
                            </button>
                          </td>
                      )}
                      {selectedTime === "yearly" && (
                          <td className="h-full w-1/2 bg-accent text-white rounded-full">
                            <button onClick={() => { setSelectedTime("yearly") }} className="h-full w-full">
                              <p className="text-center font-bold uppercase">
                                yearly
                              </p>
                            </button>
                          </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <table className="w-full mt-16 h-px table-fixed hidden md:table">
            <thead>
              <tr>
                <th className="py-4 px-6 text-sm font-semibold text-black text-left w-1/5" scope="col">
                  <span>Plans</span>
                </th>
                {pricing_plans.map((item, key) => {
                  return(
                    <th key={key} className="w-1/5 py-4 px-6 text-lg leading-6 font-medium text-black text-left" scope="col">
                      {item.name}
                    </th>   
                  )
                })}                      
              </tr>
            </thead>

            <tbody className="border-t border-gray-200 divide-y divide-gray-300">
              <tr>
                <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                  Price
                </th>

                {pricing_plans.map((item, key) => {
                  return(
                    <td key={key} className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">                          
                          {item.price === "FREE" && (item.price)} 
                          {item.price !== "FREE" && (`$${item.price} / month`)} 
                        </span>
                      </div>
                    </td>  
                  )
                })}                           
              </tr>

                            
              <tr className="bg-accent/20 w-full">
                <td className="py-4 px-6 w-full font-bold text-black text-left align-top" colSpan="5">
                  {features[0].category}
                </td>
              </tr>

              {features[0].subcategory.map((subcategory, key) => {
                return(
                  <tr key={key} className="">
                    <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                      {subcategory.name}
                    </th>
                  
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'string' && (pricing_plans[0].feature_answers.ecommerce[key].name)}
                          {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'boolean' && pricing_plans[0].feature_answers.ecommerce[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'boolean' && !pricing_plans[0].feature_answers.ecommerce[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[1].feature_answers.ecommerce[key].name == 'string' && (pricing_plans[1].feature_answers.ecommerce[key].name)}
                          {typeof pricing_plans[1].feature_answers.ecommerce[key].name == 'boolean' && pricing_plans[1].feature_answers.ecommerce[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[1].feature_answers.ecommerce[key].name == 'boolean' && !pricing_plans[1].feature_answers.ecommerce[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[2].feature_answers.ecommerce[key].name == 'string' && (pricing_plans[2].feature_answers.ecommerce[key].name)}
                          {typeof pricing_plans[2].feature_answers.ecommerce[key].name == 'boolean' && pricing_plans[2].feature_answers.ecommerce[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[2].feature_answers.ecommerce[key].name == 'boolean' && !pricing_plans[2].feature_answers.ecommerce[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 
                    
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[3].feature_answers.ecommerce[key].name == 'string' && (pricing_plans[3].feature_answers.ecommerce[key].name)}
                          {typeof pricing_plans[3].feature_answers.ecommerce[key].name == 'boolean' && pricing_plans[3].feature_answers.ecommerce[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[3].feature_answers.ecommerce[key].name == 'boolean' && !pricing_plans[3].feature_answers.ecommerce[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
       
              <tr className="bg-accent/20 w-full">
                <td className="py-4 px-6 w-full font-bold text-black text-left align-top" colSpan="5">
                  {features[1].category}
                </td>
              </tr>

              {features[1].subcategory.map((subcategory, key) => {
                return(
                  <tr key={key} className="">
                    <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                      {subcategory.name}
                    </th>
                  
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[0].feature_answers.platform[key].name == 'string' && (pricing_plans[0].feature_answers.platform[key].name)}
                          {typeof pricing_plans[0].feature_answers.platform[key].name == 'boolean' && pricing_plans[0].feature_answers.platform[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[0].feature_answers.platform[key].name == 'boolean' && !pricing_plans[0].feature_answers.platform[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[1].feature_answers.platform[key].name == 'string' && (pricing_plans[1].feature_answers.platform[key].name)}
                          {typeof pricing_plans[1].feature_answers.platform[key].name == 'boolean' && pricing_plans[1].feature_answers.platform[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[1].feature_answers.platform[key].name == 'boolean' && !pricing_plans[1].feature_answers.platform[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[2].feature_answers.platform[key].name == 'string' && (pricing_plans[2].feature_answers.platform[key].name)}
                          {typeof pricing_plans[2].feature_answers.platform[key].name == 'boolean' && pricing_plans[2].feature_answers.platform[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[2].feature_answers.platform[key].name == 'boolean' && !pricing_plans[2].feature_answers.platform[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 
                    
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[3].feature_answers.platform[key].name == 'string' && (pricing_plans[3].feature_answers.platform[key].name)}
                          {typeof pricing_plans[3].feature_answers.platform[key].name == 'boolean' && pricing_plans[3].feature_answers.platform[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[3].feature_answers.platform[key].name == 'boolean' && !pricing_plans[3].feature_answers.platform[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
              
       
              <tr className="bg-accent/20 w-full">
                <td className="py-4 px-6 w-full font-bold text-black text-left align-top" colSpan="5">
                  {features[2].category}
                </td>
              </tr>

              {features[2].subcategory.map((subcategory, key) => {
                return(
                  <tr key={key} className="">
                    <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                      {subcategory.name}
                    </th>
                  
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[0].feature_answers.marketing[key].name == 'string' && (pricing_plans[0].feature_answers.marketing[key].name)}
                          {typeof pricing_plans[0].feature_answers.marketing[key].name == 'boolean' && pricing_plans[0].feature_answers.marketing[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[0].feature_answers.marketing[key].name == 'boolean' && !pricing_plans[0].feature_answers.marketing[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[1].feature_answers.marketing[key].name == 'string' && (pricing_plans[1].feature_answers.marketing[key].name)}
                          {typeof pricing_plans[1].feature_answers.marketing[key].name == 'boolean' && pricing_plans[1].feature_answers.marketing[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[1].feature_answers.marketing[key].name == 'boolean' && !pricing_plans[1].feature_answers.marketing[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[2].feature_answers.marketing[key].name == 'string' && (pricing_plans[2].feature_answers.marketing[key].name)}
                          {typeof pricing_plans[2].feature_answers.marketing[key].name == 'boolean' && pricing_plans[2].feature_answers.marketing[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[2].feature_answers.marketing[key].name == 'boolean' && !pricing_plans[2].feature_answers.marketing[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 
                    
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[3].feature_answers.marketing[key].name == 'string' && (pricing_plans[3].feature_answers.marketing[key].name)}
                          {typeof pricing_plans[3].feature_answers.marketing[key].name == 'boolean' && pricing_plans[3].feature_answers.marketing[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[3].feature_answers.marketing[key].name == 'boolean' && !pricing_plans[3].feature_answers.marketing[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
              
       
              <tr className="bg-accent/20 w-full">
                <td className="py-4 px-6 w-full font-bold text-black text-left align-top" colSpan="5">
                  {features[3].category}
                </td>
              </tr>

              {features[3].subcategory.map((subcategory, key) => {
                return(
                  <tr key={key} className="">
                    <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                      {subcategory.name}
                    </th>
                  
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[0].feature_answers.payments[key].name == 'string' && (pricing_plans[0].feature_answers.payments[key].name)}
                          {typeof pricing_plans[0].feature_answers.payments[key].name == 'boolean' && pricing_plans[0].feature_answers.payments[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[0].feature_answers.payments[key].name == 'boolean' && !pricing_plans[0].feature_answers.payments[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[1].feature_answers.payments[key].name == 'string' && (pricing_plans[1].feature_answers.payments[key].name)}
                          {typeof pricing_plans[1].feature_answers.payments[key].name == 'boolean' && pricing_plans[1].feature_answers.payments[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[1].feature_answers.payments[key].name == 'boolean' && !pricing_plans[1].feature_answers.payments[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td>

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[2].feature_answers.payments[key].name == 'string' && (pricing_plans[2].feature_answers.payments[key].name)}
                          {typeof pricing_plans[2].feature_answers.payments[key].name == 'boolean' && pricing_plans[2].feature_answers.payments[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[2].feature_answers.payments[key].name == 'boolean' && !pricing_plans[2].feature_answers.payments[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        
                        </span>
                      </div>
                    </td> 
                    
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[3].feature_answers.payments[key].name == 'string' && (pricing_plans[3].feature_answers.payments[key].name)}
                          {typeof pricing_plans[3].feature_answers.payments[key].name == 'boolean' && pricing_plans[3].feature_answers.payments[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[3].feature_answers.payments[key].name == 'boolean' && !pricing_plans[3].feature_answers.payments[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                      
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
       
              <tr className="bg-accent/20 w-full">
                <td className="py-4 px-6 w-full font-bold text-black text-left align-top" colSpan="5">
                  {features[4].category}
                </td>
              </tr>

              {features[4].subcategory.map((subcategory, key) => {
                return(
                  <tr key={key} className="">
                    <th className="py-4 px-6 font-semibold text-black text-left align-top" scope="row">
                      {subcategory.name}
                    </th>
                  
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[0].feature_answers.support[key].name == 'string' && (pricing_plans[0].feature_answers.support[key].name)}
                          {typeof pricing_plans[0].feature_answers.support[key].name == 'boolean' && pricing_plans[0].feature_answers.support[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[0].feature_answers.support[key].name == 'boolean' && !pricing_plans[0].feature_answers.support[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        </span>
                      </div>
                    </td> 

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[1].feature_answers.support[key].name == 'string' && (pricing_plans[1].feature_answers.support[key].name)}
                          {typeof pricing_plans[1].feature_answers.support[key].name == 'boolean' && pricing_plans[1].feature_answers.support[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[1].feature_answers.support[key].name == 'boolean' && !pricing_plans[1].feature_answers.support[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        </span>
                      </div>
                    </td>

                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[2].feature_answers.support[key].name == 'string' && (pricing_plans[2].feature_answers.support[key].name)}
                          {typeof pricing_plans[2].feature_answers.support[key].name == 'boolean' && pricing_plans[2].feature_answers.support[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[2].feature_answers.support[key].name == 'boolean' && !pricing_plans[2].feature_answers.support[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        </span>
                      </div>
                    </td> 
                    
                    <td className="h-full py-4 px-6 align-top">
                      <div className="relative h-full w-full table text-left">
                        <span className="">    
                          {typeof pricing_plans[3].feature_answers.support[key].name == 'string' && (pricing_plans[3].feature_answers.support[key].name)}
                          {typeof pricing_plans[3].feature_answers.support[key].name == 'boolean' && pricing_plans[3].feature_answers.support[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                          {typeof pricing_plans[3].feature_answers.support[key].name == 'boolean' && !pricing_plans[3].feature_answers.support[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
              
            </tbody>

          </table>

          <div className="md:hidden">         
                <table className="w-full bg-accent/20 rounded-xl mt-16 h-px table-fixed md:hidden table">
                  <thead>
                    <tr>
                      <th className="w-full py-3 px-4 text-lg leading-6 font-medium text-black text-center" scope="col">
                        {pricing_plans[0].name}
                      </th>                       
                    </tr>
                  </thead>

                  <tbody className="border-t border-gray-200 divide-y w-full divide-gray-300">
                    <tr className='w-full'>
                      <th className="py-3 px-4 font-semibold text-black text-left align-top w-1/2" scope="row">
                        Price
                      </th>   
                      <th className="py-3 px-4 font-semibold text-black text-left align-top w-full" scope="row">                      
                          {pricing_plans[0].price === "FREE" && (pricing_plans[0].price)} 
                          {pricing_plans[0].price !== "FREE" && (`$${pricing_plans[0].price} / month`)} 
                      </th>                       
                    </tr>

                                  
                    <tr className="bg-accent/20 w-full">
                      <td className="py-3 px-4 w-full font-bold text-black text-left align-top" colSpan="5">
                        {features[0].category}
                      </td>
                    </tr>

                    {features[0].subcategory.map((subcategory, key) => {
                      return(
                        <tr key={key} className="">
                          <th className="py-3 px-4 font-semibold text-black text-left align-top" scope="row">
                            {subcategory.name}
                          </th>
                        
                          <td className="h-full py-3 px-4 align-top">
                            <div className="relative h-full w-full table text-left">
                              <span className="">    
                                {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'string' && (pricing_plans[0].feature_answers.ecommerce[key].name)}
                                {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'boolean' && pricing_plans[0].feature_answers.ecommerce[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                                {typeof pricing_plans[0].feature_answers.ecommerce[key].name == 'boolean' && !pricing_plans[0].feature_answers.ecommerce[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                              
                              </span>
                            </div>
                          </td> 
                        </tr>
                      )
                    })}
            
                    <tr className="bg-accent/20 w-full">
                      <td className="py-3 px-4 w-full font-bold text-black text-left align-top" colSpan="5">
                        {features[1].category}
                      </td>
                    </tr>

                    {features[1].subcategory.map((subcategory, key) => {
                      return(
                        <tr key={key} className="">
                          <th className="py-3 px-4 font-semibold text-black text-left align-top" scope="row">
                            {subcategory.name}
                          </th>
                        
                          <td className="h-full py-3 px-4 align-top">
                            <div className="relative h-full w-full table text-left">
                              <span className="">    
                                {typeof pricing_plans[0].feature_answers.platform[key].name == 'string' && (pricing_plans[0].feature_answers.platform[key].name)}
                                {typeof pricing_plans[0].feature_answers.platform[key].name == 'boolean' && pricing_plans[0].feature_answers.platform[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                                {typeof pricing_plans[0].feature_answers.platform[key].name == 'boolean' && !pricing_plans[0].feature_answers.platform[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                              
                              </span>
                            </div>
                          </td> 
                        </tr>
                      )
                    })}
                    
            
                    <tr className="bg-accent/20 w-full">
                      <td className="py-3 px-4 w-full font-bold text-black text-left align-top" colSpan="5">
                        {features[2].category}
                      </td>
                    </tr>

                    {features[2].subcategory.map((subcategory, key) => {
                      return(
                        <tr key={key} className="">
                          <th className="py-3 px-4 font-semibold text-black text-left align-top" scope="row">
                            {subcategory.name}
                          </th>
                        
                          <td className="h-full py-3 px-4 align-top">
                            <div className="relative h-full w-full table text-left">
                              <span className="">    
                                {typeof pricing_plans[0].feature_answers.marketing[key].name == 'string' && (pricing_plans[0].feature_answers.marketing[key].name)}
                                {typeof pricing_plans[0].feature_answers.marketing[key].name == 'boolean' && pricing_plans[0].feature_answers.marketing[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                                {typeof pricing_plans[0].feature_answers.marketing[key].name == 'boolean' && !pricing_plans[0].feature_answers.marketing[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                              
                              </span>
                            </div>
                          </td> 
                        </tr>
                      )
                    })}
                    
            
                    <tr className="bg-accent/20 w-full">
                      <td className="py-3 px-4 w-full font-bold text-black text-left align-top" colSpan="5">
                        {features[3].category}
                      </td>
                    </tr>

                    {features[3].subcategory.map((subcategory, key) => {
                      return(
                        <tr key={key} className="">
                          <th className="py-3 px-4 font-semibold text-black text-left align-top" scope="row">
                            {subcategory.name}
                          </th>
                        
                          <td className="h-full py-3 px-4 align-top">
                            <div className="relative h-full w-full table text-left">
                              <span className="">    
                                {typeof pricing_plans[0].feature_answers.payments[key].name == 'string' && (pricing_plans[0].feature_answers.payments[key].name)}
                                {typeof pricing_plans[0].feature_answers.payments[key].name == 'boolean' && pricing_plans[0].feature_answers.payments[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                                {typeof pricing_plans[0].feature_answers.payments[key].name == 'boolean' && !pricing_plans[0].feature_answers.payments[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                              
                              </span>
                            </div>
                          </td> 
                        </tr>
                      )
                    })}
            
                    <tr className="bg-accent/20 w-full">
                      <td className="py-3 px-4 w-full font-bold text-black text-left align-top" colSpan="5">
                        {features[4].category}
                      </td>
                    </tr>

                    {features[4].subcategory.map((subcategory, key) => {
                      return(
                        <tr key={key} className="">
                          <th className="py-3 px-4 font-semibold text-black text-left align-top" scope="row">
                            {subcategory.name}
                          </th>
                        
                          <td className="h-full py-3 px-4 align-top">
                            <div className="relative h-full w-full table text-left">
                              <span className="">    
                                {typeof pricing_plans[0].feature_answers.support[key].name == 'string' && (pricing_plans[0].feature_answers.support[key].name)}
                                {typeof pricing_plans[0].feature_answers.support[key].name == 'boolean' && pricing_plans[0].feature_answers.support[key].name && (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.8543 0.632801C16.8367 0.652901 16.8203 0.674295 16.805 0.696853L8.25642 13.5799L3.10461 7.4834C2.75465 7.09769 2.29178 6.88771 1.81352 6.89769C1.33526 6.90767 0.878942 7.13684 0.540706 7.5369C0.202471 7.93697 0.008726 8.47671 0.000287614 9.0424C-0.00815077 9.6081 0.169376 10.1556 0.495469 10.5695L7.00847 18.2761C7.18392 18.4832 7.39286 18.6465 7.62281 18.756C7.85275 18.8656 8.099 18.9193 8.34687 18.9138C8.59473 18.9084 8.83913 18.844 9.06549 18.7244C9.29185 18.6048 9.49552 18.4325 9.66437 18.2178L19.4905 3.6898C19.8251 3.27443 20.008 2.72 19.9997 2.14636C19.9915 1.57273 19.7927 1.02599 19.4463 0.624342C19.0999 0.222699 18.6339 -0.00157456 18.1488 8.32215e-06C17.6638 0.0015912 17.1987 0.228903 16.8543 0.632801Z" fill="black"/></svg>)}
                                {typeof pricing_plans[0].feature_answers.support[key].name == 'boolean' && !pricing_plans[0].feature_answers.support[key].name && (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.71146 8.71122C8.79855 8.62391 8.902 8.55464 9.0159 8.50738C9.12979 8.46012 9.2519 8.43579 9.37521 8.43579C9.49852 8.43579 9.62063 8.46012 9.73452 8.50738C9.84842 8.55464 9.95188 8.62391 10.039 8.71122L15.0002 13.6743L19.9615 8.71122C20.0486 8.62405 20.1521 8.55491 20.266 8.50774C20.3799 8.46056 20.5019 8.43628 20.6252 8.43628C20.7485 8.43628 20.8705 8.46056 20.9844 8.50774C21.0983 8.55491 21.2018 8.62405 21.289 8.71122C21.3761 8.79838 21.4453 8.90186 21.4924 9.01575C21.5396 9.12963 21.5639 9.2517 21.5639 9.37497C21.5639 9.49824 21.5396 9.6203 21.4924 9.73419C21.4453 9.84807 21.3761 9.95155 21.289 10.0387L16.3258 15L21.289 19.9612C21.3761 20.0484 21.4453 20.1519 21.4924 20.2657C21.5396 20.3796 21.5639 20.5017 21.5639 20.625C21.5639 20.7482 21.5396 20.8703 21.4924 20.9842C21.4453 21.0981 21.3761 21.2016 21.289 21.2887C21.2018 21.3759 21.0983 21.445 20.9844 21.4922C20.8705 21.5394 20.7485 21.5637 20.6252 21.5637C20.5019 21.5637 20.3799 21.5394 20.266 21.4922C20.1521 21.445 20.0486 21.3759 19.9615 21.2887L15.0002 16.3256L10.039 21.2887C9.9518 21.3759 9.84832 21.445 9.73443 21.4922C9.62054 21.5394 9.49848 21.5637 9.37521 21.5637C9.25194 21.5637 9.12988 21.5394 9.01599 21.4922C8.9021 21.445 8.79863 21.3759 8.71146 21.2887C8.6243 21.2016 8.55515 21.0981 8.50798 20.9842C8.46081 20.8703 8.43653 20.7482 8.43653 20.625C8.43653 20.5017 8.46081 20.3796 8.50798 20.2657C8.55515 20.1519 8.6243 20.0484 8.71146 19.9612L13.6746 15L8.71146 10.0387C8.62415 9.95163 8.55489 9.84818 8.50762 9.73428C8.46036 9.62038 8.43604 9.49828 8.43604 9.37497C8.43604 9.25165 8.46036 9.12955 8.50762 9.01565C8.55489 8.90176 8.62415 8.7983 8.71146 8.71122Z" fill="black"/></svg>)}
                              </span>
                            </div>
                          </td> 
                        </tr>
                      )
                    })}
                    
                  </tbody>

                </table>
          </div>

        </div>

        <Footer />
    </div>
  )
}

export default Features