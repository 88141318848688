import React, { useEffect } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { heading, sub_heading } from '../configs/Explore_Page.config'
import { useState } from 'react'
import axios from 'axios'
import { AUTHENTICATION_URL, SEARCH_URL } from '../configs/api/api.config'
import Card from '../components/explore/Card'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'


function Features() {
  const [newStores, setNewStores] = useState([])
  // const [mostPopularStores, setMostPopularStores] = useState([])
  const [mostPopularStores, setMostPopularStores] = useState([{
    store_id: 1,
    user_id: 1,
    name: "Store name",
    description: "This is the description of the store, here can be some information about the developer them self or the products they sell.",
    under_review: 0,
    products: 6,
    sales: 3
  }])

  useEffect(() => {
    axios.get(SEARCH_URL + "most-popular")
      .then((response) => {
        setMostPopularStores(response.data.data)
      })
  }, [])

  useEffect(() => {
    axios.get(SEARCH_URL + "new")
      .then((response) => {
        setNewStores(response.data.data)
      })
  }, [])
  

  const slideLeft = () => {
    const slider = document.getElementById("slider")    
    slider.scrollLeft = slider.scrollLeft - 400
  }

  const slideRigth = () => {
    const slider = document.getElementById("slider")    
    slider.scrollLeft = slider.scrollLeft + 400
  }

  const slideLeft2 = () => {
    const slider = document.getElementById("slider2")    
    slider.scrollLeft = slider.scrollLeft - 400
  }

  const slideRigth2 = () => {
    const slider = document.getElementById("slider2")    
    slider.scrollLeft = slider.scrollLeft + 400
  }
  

  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          console.log(response)
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
        <Header user={user} role={role} />



        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">{heading}</p>
            <p className="text-lg mt-4 w-3/4 md:w-1/2 lg:w-1/3 mx-auto">{sub_heading}</p>
        
            {/* Most popular */}
            <div id="most-popular" className="mt-20">
              <p className="font-bold text-xl text-left ml-8">Most Popular</p>
              <div className="flex">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <svg onClick={slideLeft} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="slider" className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth">
                  {mostPopularStores && (mostPopularStores.map((store, key) => {
                    return (
                      <Card key={key} store={store} />
                    )
                  }))}
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <svg onClick={slideRigth} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Newest */}
            <div id="most-popular" className="mt-20">
              <p className="font-bold text-xl text-left ml-8">New</p>
              <div className="flex">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <svg onClick={slideLeft2} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="slider2" className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth">
                  {newStores && (newStores.map((store, key) => {
                    return (
                      <Card key={key} store={store} />
                    )
                  }))}
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <svg onClick={slideRigth2} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Features