import React from 'react'

import { heading, subheading, call_to_action, call_to_action_page } from '../../configs/home/GetStarted.config'
import GetStarted from "../../assets/svg/get_started.svg"

function Features() {
  return (
    <div className=''>
        <div className="bg-white">
            <div className="container mx-auto pt-40">
              <p className="text-2xl font-extrabold text-center">{heading}</p>
              <p className="text-center w-3/4 md:w-1/2 lg:w-1/3 mx-auto py-3">{subheading}</p>
            
              <a href={call_to_action_page} className="">
                <div className="bg-accent rounded-3xl w-fit min-w-[12%] mx-auto mt-5">
                  <p className='text-lg text-center font-medium text-white py-2 px-6'>                    
                    {call_to_action}
                  </p>
                </div>
              </a>
            </div>

            {/* <img src={GetStarted} className="max-w-[1000px] w-[33%]" alt="hero 1 svg" />  */}
        </div>    
    </div>
  )
}

export default Features