import axios from 'axios'
import React from 'react'
import Cookies from 'universal-cookie'
import { PRODUCTS_URL, PRODUCT_THUMBNAIL_UPLOADS_URL } from '../../../../configs/api/api.config'
import fileDownload from 'js-file-download'

function Item({item}) {
  const newCookies = new Cookies()
  
  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function handleDownload(){
    const user = getCookie()

    axios.get(PRODUCTS_URL + "download?product_id=" + item.product_id, { 
      headers: {
        "x-access-token": user
      },
      responseType: 'blob'
    })
      .then((response) => {
        const extension = item.asset.split(".").pop()
        fileDownload(response.data, item.title + "." + extension)
      })
      // .then(res => {
      //   try {
      //     const url = window.URL.createObjectURL(new Blob([res.data]));
      //     const a = document.createElement('a');
      //     a.style.display = 'none';
      //     a.href = url;
      //     a.download = item.title + '.' + item.asset.split(".").pop();
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      //   } catch (error) {
      //     console.error(error);
      //   }
      // })
  }

  return (
      <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
        <div className="flex flex-row m-3 h-auto">
          <a href={"/s/" + item.store_name + "/" + item.product_id}>
            <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + item.thumbnail} alt="" className='h-[100px] w-[100px] object-cover rounded' />
          </a>
          
          <div className="ml-2 text-left w-full">
            <p className="text-lg">{item.title}</p>
            <p className="font-light">{item.description}</p>
            <button className='mt-4 -ml-1 p-1' onClick={handleDownload}>
              <p className="">
                Download
              </p>
            </button>
          </div>
          <div className="float-right text-right mr-2">
            <table className="h-full">
              <tbody className="h-full">
                <tr className="h-full">
                  <td className="align-middle">
                    <p className="align-middle inline-block">${(item.paid / 100)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {item.time && (<p className="text-xs ml-3 mb-2">{item.time.substring(0, 10)}</p>)}
      </div>
  )
}

export default Item