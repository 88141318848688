import React from 'react'
import Cookies from 'universal-cookie'
import { PRODUCTS_URL, PRODUCT_THUMBNAIL_UPLOADS_URL } from '../../../../configs/api/api.config'

function Item({item}) {
  const newCookies = new Cookies()
  
  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  if(item.type === "transfer"){
    return (
      <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
        <div className="flex flex-row m-3">
          <div className="w-full">
            <p className="text-sm py-2 px-4">TRANSFER</p>

            <p className="px-4">
              FROM: {item.to_balance === "buyer" && "seller"}{item.to_balance === "seller" && "buyer"} account
            </p>
            <p className="px-4">
              TO: {item.to_balance} account
            </p>

            <p className="text-xs px-4 pt-2">{item.time.substring(0, 10)}</p>
          </div>

          <div className="float-right text-right mr-2">
            <table className="h-full">
              <tbody className="h-full">
                <tr className="h-full">
                  <td className="align-middle">
                    <p className="align-middle inline-block">${(item.amount / 100)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  } else if(item.type === "deposit"){
    return (
      <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
        <div className="flex flex-row m-3">
          <div className="w-full">
            <p className="text-sm py-2 px-4">DEPOSIT</p>

            <p className=" px-4">
              FROM: {item.to_balance === "buyer" && "seller"}{item.to_balance === "seller" && "buyer"} account
            </p>
            <p className=" px-4">
              TO: {item.to_balance} account
            </p>
            
            <p className="text-xs px-4 pt-2">{item.time.substring(0, 10)}</p>
          </div>

          <div className="float-right text-right mr-2">
            <table className="h-full">
              <tbody className="h-full">
                <tr className="h-full">
                  <td className="align-middle">
                    <p className="align-middle inline-block">${(item.amount / 100)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  } else if(item.type === "payout"){
    return (
      <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
        <div className="flex flex-row m-3">
          <div className="w-full">
            <p className="text-sm py-2 px-4">PAYOUT</p>

            <p className=" px-4">
              FROM: seller account
            </p>
            
            <p className="text-xs px-4 pt-2">{item.time.substring(0, 10)}</p>
          </div>

          <div className="float-right text-right mr-2">
            <table className="h-full">
              <tbody className="h-full">
                <tr className="h-full">
                  <td className="align-middle">
                    <p className="align-middle inline-block">${(item.amount / 100)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default Item