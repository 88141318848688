import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { heading, sub_heading, features, trusted_by, amount_sellers, pricing_subheading, pricing_heading, pricing_plans, pricing_url } from '../configs/Features_Page.config'
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

function Features() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          console.log(response)
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
      <Header user={user} role={role} />



        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">{heading}</p>
            <p className="text-lg mt-4 w-3/4 md:w-1/2 lg:w-1/3 mx-auto">{sub_heading}</p>
        
            {/* Feature list */}
            <div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-20">
              {features.map((feature, key) => {
                  return(
                      <div key={key} className="bg-white rounded-xl">
                          <div className="bg-accent/20 h-full rounded-xl">
                              <div className="ml-3 pt-2">
                                  <table>
                                      <tbody>
                                          <tr>
                                              <td className='align-middle'>
                                                  <svg className='mr-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" dangerouslySetInnerHTML={{__html: feature.svg}}></svg>
                                              </td>
                                              <td className='align-middle'>
                                                  <p className="text-xl font-semibold text-left">{feature.feature_name}</p>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
              
                              <p className="ml-3 pb-6 pr-2 text-left">{feature.feature_description}</p>
                          </div>
                      </div>
                  )
              })}
            </div>

            <p className="text-lg font-medium mt-6 mx-auto">and a lot more that's coming soon...</p>

        </div>

        {/* Trusted by */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#818FC0" fillOpacity="1" d="M0,256L80,218.7C160,181,320,107,480,112C640,117,800,203,960,208C1120,213,1280,139,1360,101.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

        <div className="bg-accent/75">
          <div className="container mx-auto">
            <p className="text-center text-white p-5 font-bold text-lg md:text-xl lg:text-2xl">Trusted by</p>

            <div className="flex flex-row mx-auto">
              {trusted_by.map((partner, key) => {
                return (
                  <div key={key} className="px-2 mx-auto">
                    <div className="bg-white rounded-2xl max-h-[75px] aspect-square">
                      <table className="h-full">
                        <tbody className="h-full">
                          <tr className="h-full">
                            <td className="h-full">
                              <img src={partner.logo_url} alt="logo partner" className='p-3' />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              })}
            </div>

            <p className="text-center text-white p-5 md:text-lg lg:text-xl">and {amount_sellers} sellers</p>
          </div>

        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#818FC0" fillOpacity="1" d="M0,256L80,218.7C160,181,320,107,480,112C640,117,800,203,960,208C1120,213,1280,139,1360,101.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
        
        {/* Ready to sell? Pricing */}
        <p className="text-center text-black p-4 font-bold text-lg md:text-xl lg:text-2xl">{pricing_heading}</p>
        <p className="text-lg w-3/4 md:w-1/2 lg:w-1/4 text-center mx-auto">{pricing_subheading}</p>

        <div className="container mx-auto mt-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {pricing_plans.map((pricing, key) => {
              return(
                <div key={key} className="bg-accent/20 m-2 rounded-xl">
                  <div className="">
                    <p className="text-small mt-4 ml-4 uppercase">{pricing.name_plan}</p>
                    {pricing.price == "FREE" && (
                      <p className="text-3xl ml-4 mb-4 font-extrabold">{pricing.price}</p>
                    )}
                    {pricing.price != "FREE" && (
                      <p className="text-3xl ml-4 mb-4 font-extrabold">${pricing.price}<span className='text-base font-normal'> / month</span></p>
                    )}

                    
                    <div id="cta_button_register" className='bg-accent rounded-3xl w-11/12 mx-auto'>
                      {!pricing.coming_soon && (
                        <a href={pricing.button_path}>
                          <p className='text-lg font-medium text-white py-1 px-4 text-center'>{pricing.button_text}</p>
                        </a>
                      )}
                      {pricing.coming_soon && (
                          <p className='text-lg font-medium text-white py-1 px-4 text-center'>Coming soon...</p>
                      )}
                    </div>

                    <div className="ml-4 py-3">
                      {pricing.features_included.map((feature, key) => {
                        return(
                          <div className="my-2" key={key}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className='align-middle'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g clip-path="url(#clip0_100_22)"><path d="M10.0833 18.75C7.76261 18.75 5.53701 17.8281 3.89607 16.1872C2.25512 14.5462 1.33325 12.3206 1.33325 10C1.33325 7.67936 2.25512 5.45376 3.89607 3.81282C5.53701 2.17187 7.76261 1.25 10.0833 1.25C12.4039 1.25 14.6295 2.17187 16.2704 3.81282C17.9114 5.45376 18.8333 7.67936 18.8333 10C18.8333 12.3206 17.9114 14.5462 16.2704 16.1872C14.6295 17.8281 12.4039 18.75 10.0833 18.75ZM10.0833 20C12.7354 20 15.279 18.9464 17.1543 17.0711C19.0297 15.1957 20.0833 12.6522 20.0833 10C20.0833 7.34784 19.0297 4.8043 17.1543 2.92893C15.279 1.05357 12.7354 0 10.0833 0C7.43109 0 4.88755 1.05357 3.01218 2.92893C1.13682 4.8043 0.083252 7.34784 0.083252 10C0.083252 12.6522 1.13682 15.1957 3.01218 17.0711C4.88755 18.9464 7.43109 20 10.0833 20Z" fill="black"/><path d="M13.7957 6.21248C13.7868 6.22111 13.7784 6.2303 13.7707 6.23998L9.42944 11.7712L6.81319 9.15373C6.63547 8.98813 6.40041 8.89798 6.15753 8.90227C5.91466 8.90655 5.68293 9.00494 5.51116 9.17671C5.33939 9.34847 5.241 9.5802 5.23672 9.82308C5.23243 10.066 5.32259 10.301 5.48819 10.4787L8.79569 13.7875C8.88479 13.8764 8.99089 13.9465 9.10767 13.9936C9.22444 14.0406 9.3495 14.0636 9.47537 14.0613C9.60124 14.059 9.72536 14.0313 9.84031 13.98C9.95526 13.9286 10.0587 13.8547 10.1444 13.7625L15.1344 7.52498C15.3043 7.34665 15.3972 7.1086 15.3931 6.86232C15.3889 6.61603 15.2879 6.38129 15.112 6.20885C14.9361 6.03641 14.6994 5.94012 14.4531 5.9408C14.2068 5.94148 13.9706 6.03907 13.7957 6.21248Z" fill="black"/></g><defs><clipPath id="clip0_100_22"><rect width="20" height="20" fill="white" transform="translate(0.083252)"/></clipPath></defs></svg>
                                  </td>
                                  <td className='align-middle'>
                                    <p className="ml-2">{feature.feature}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          
          <a href={pricing_url}>
            <p className="text-accent underline text-center pt-5">View full pricing details</p>
          </a>
        </div>



        <Footer />
    </div>
  )
}

export default Features