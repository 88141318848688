import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AUTHENTICATION_URL, PAYMENTS_URL, PRODUCTS_URL, PRODUCT_THUMBNAIL_UPLOADS_URL, STORES_URL } from '../configs/api/api.config'
import Cookies from 'universal-cookie'
import fileDownload from 'js-file-download'

import NotFound from './NotFound'
import Warning from '../components/states/WarningOnTop'
import Success from '../components/states/SuccessOnTop'
import Error from '../components/states/ErrorOnTop'

import Header from '../components/webshop/Header'
import Login from '../components/webshop/Login'
import Register from '../components/webshop/Register'
import Product from '../components/webshop/Product'
import Footer from '../components/webshop/Footer'
import scrollToTop from '../hooks/scrollToTop'

function Webshop() {
    const newCookies = new Cookies()
    const { store_name, page } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const [user, setUser] = useState(0)
    const [role, setRole] = useState("login")
    const [loggedInOrRegistered, setLoggedInOrRegistered] = useState(false)
    const [amountToRemoveToBuyerBalance, setAmountToRemoveToBuyerBalance] = useState(0)
  
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [warning, setWarning] = useState("")

    const [isNotFound, setIsNotFound] = useState(false)

    const [store, setStore] = useState([])
    const [products, setProducts] = useState([])
    const [openedProductFound, setOpenedProductFound] = useState(false)
    const [openedProduct, setOpenedProduct] = useState()
    const [userOwnsProduct, setUserOwnsProduct] = useState(false)

    const [isBuyPopup, setIsBuyPopup] = useState(false)
    const [isReportingStore, setIsReportingStore] = useState(false)
    const [isReportingProduct, setIsReportingProduct] = useState(false)
    const [reportMessage, setReportMessage] = useState("")

    const styles = {
        theme_text: {
            color: store.theme_color
        },
        theme_bg: {
            backgroundColor: store.theme_color
        },
        theme_border: {
            borderColor: store.theme_color
        }
    }

    useEffect(() => {
      axios.get(STORES_URL + "?name=" + store_name)
        .then((response) => {
            if(response.data.success){
                setStore(response.data.data.store)
                setProducts(response.data.data.products)
            } else {
                setIsNotFound(true) 
            }
        })
    }, [])
    
    function getCookie(){
        if(newCookies.get('user')){
            return newCookies.get('user')
        }
    }  

    function getUser(){
        const user = getCookie()

        if(user){
        axios.get(AUTHENTICATION_URL, {
            headers: {
            'x-access-token': user
            }
        })
            .then((response) => {
                if(response.data.success){
                    setUser(response.data.user_id)
                    setRole(response.data.role)
                } else {
                    newCookies.remove("user", {path: "/"})
                }
            })
        }      
    }

    function getProduct(){
        axios.get(PRODUCTS_URL + "?product_id=" + searchParams.get("product"))
            .then((response) => {
                if(response.data.success){
                    setOpenedProduct(response.data.data)                    
                } else {
                    setError("Couldn't retrieve the searched product.")
                    scrollToTop()
                }
            })        
    }

    function doesUserOwnProduct(){
        const userCookie = getCookie()

        if(userCookie && user > 0){
            axios.get(PRODUCTS_URL + "bought?product_id=" + searchParams.get("product"), {
                headers: {
                    "x-access-token": userCookie
                }
            })
                .then((response) => {
                    if(response.data.success){
                        setUserOwnsProduct(true)
                        setOpenedProductFound(true)
                    } else {
                        setUserOwnsProduct(false)
                        setOpenedProductFound(true)
                    }
                })
        } else {
            setUserOwnsProduct(false)
            setOpenedProductFound(true)
        }
    }

    useEffect(() => {
        getUser()
        getProduct()
    }, [loggedInOrRegistered])

    useEffect(() => {
        doesUserOwnProduct()
    }, [user])


    function handleBuyProduct(){
        const user = getCookie()

        if(!user){
            setWarning("You should be logged in to buy this product.")
            setError("")
            setSuccess("")
            scrollToTop()

            getUser()
        } else {
            if(sessionStorage.getItem("cart")){
                const items = sessionStorage.getItem("cart").split(',')

                for(let i = 0; i < items.length; i++){
                    if(items[i] === (openedProduct.product_id).toString()){
                        setWarning("")
                        setSuccess("")
                        setError("You can't add this product more than once to your cart.")   
                        scrollToTop()
                        return 
                    }
                }

                sessionStorage.setItem("cart", sessionStorage.getItem("cart") + "," + openedProduct.product_id)
            //     let newCart = []
            //     const cart = JSON.parse(sessionStorage.getItem("cart"))
                
            //     for(let i = 0; i < cart.length; i++){
            //         newCart.push(cart[i])                  
            //     }

            //     newCart.push({
            //         product_id: openedProduct.product_id,
            //         title: openedProduct.title,
            //         thumbnail: openedProduct.thumbnail,
            //         price: openedProduct.price
            //     })    

            //     sessionStorage.cart = JSON.stringify(newCart)
            } else {
                sessionStorage.setItem("cart", openedProduct.product_id)
            //     sessionStorage.setItem("cart", JSON.stringify([{
            //         product_id: openedProduct.product_id,
            //         title: openedProduct.title,
            //         thumbnail: openedProduct.thumbnail,
            //         price: openedProduct.price
            //     }]))
            }
                /*
            axios.post(PAYMENTS_URL + "buy", {
                product_id: openedProduct.product_id
            }, {
                headers: {
                    "x-access-token": user
                }
            })
                .then((response) => {
                    if(response.data.success){
                        setSuccess("The product was bought successfully")
                        setUserOwnsProduct(true)
                        setAmountToRemoveToBuyerBalance(openedProduct.price)
                    } else {
                        setError(response.data.message)
                    }
                    scrollToTop()
                })
                */
        }

        setWarning("")
        setError("")
        setSuccess("Product has been added to your cart.")
        scrollToTop()
    }

    function handleDownload(){
        const user = getCookie()

        
        axios.get(PRODUCTS_URL + "download?product_id=" + openedProduct.product_id, { 
          headers: {
            "x-access-token": user,
          },
          responseType: 'blob'
        })
          .then((response) => {
            const extension = openedProduct.asset.split('.')[openedProduct.asset.split('.').length -1]
            fileDownload(response.data, openedProduct.title + "." + extension)
          })
      }

      function handleReportStore(){
        if(isReportingStore){
            setIsReportingStore(false)
        } else {
            setIsReportingStore(true)
        }
      }

      function handleReportProduct(){
        if(isReportingProduct){
            setIsReportingProduct(false)
        } else {
            setIsReportingProduct(true)
        }        
      }

      function reportStore(){
        axios.post(STORES_URL + "report", {
            store_id: store.store_id,
            reason: reportMessage,
            reported_by: user
        })
            .then((response) => {
                if(response.data.success){
                    setSuccess(response.data.message)
                } else {
                    setError(response.data.message)
                }
            })
      } 

      function reportProduct(){
        axios.post(PRODUCTS_URL + "report", {
            product_id: openedProduct.product_id,
            reason: reportMessage,
            reported_by: user
        })
            .then((response) => {
                if(response.data.success){
                    setSuccess(response.data.message)
                } else {
                    setError(response.data.message)
                }
            })
      }

    if(isNotFound){
        return ( <NotFound /> )
    } else {
        if(store.store_id > 0){
            if(page && page === "about"){
                return (
                    <div>
                        <Header user={user} role={role} store_name={store_name} amount_to_remove_to_buyer_balance={amountToRemoveToBuyerBalance} theme_color={store.theme_color} />

                        {searchParams.get('action') && searchParams.get('action') === 'login' && (
                            <Login theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                        )}

                        {searchParams.get('action') && searchParams.get('action') === 'register' && (
                            <Register theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                        )}

                        <div className="mx-auto pt-20 md:pt-32 lg:pt-40 pb-96 text-center container">
                            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">About "<span style={styles.theme_text}>{store_name}</span>"</p>
                            <p className="mt-6 w-1/3 mx-auto">{store.description}</p>
                        </div>
                                
                        <Footer />
                    </div>
                )
            } else {
                if(searchParams.get("product") > 0){

                    if(openedProductFound && openedProduct){
                        return (
                            <div className="flex flex-col min-h-screen">
                                <Header user={user} role={role} store_name={store_name} amount_to_remove_to_buyer_balance={amountToRemoveToBuyerBalance} theme_color={store.theme_color} />

                                {searchParams.get('action') && searchParams.get('action') === 'login' && (
                                    <Login theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                                )}
                                
                                {searchParams.get('action') && searchParams.get('action') === 'register' && (
                                    <Register theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                                )}
                                
                                <div className="flex-1 mx-auto pt-20 md:pt-32 lg:pt-40 text-center container">
                                    <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">{openedProduct.title}</p>
    
                                    <div className="mt-6 md:flex md:flex-row">
                                        <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + openedProduct.thumbnail} alt="product thumbnail" className='rounded w-full h-full md:max-h-2/3 md:max-w-[300px] lg:max-w-[750px] md:min-h-[200px] lg:min-h-[250px]  object-cover' />
                                        
                                        <div className="ml-0 mt-4 md:mt-0 md:ml-8 text-left w-full">
                                            <p className="font-light">Delivered as: .{openedProduct.asset.split('.')[openedProduct.asset.split('.').length -1]}</p>
                                            
                                            <div className="mt-6">
                                                <p className="text-lg font-medium">Description</p>
                                                <p className="text">{openedProduct.description}</p>
                                            </div>
                                            
                                            <p className="text-lg font-medium mt-8">Price: <span className="font-normal">${openedProduct.price/100}</span></p>
                                        </div>
                                    </div>    

                                    {!userOwnsProduct &&
                                        <button className="w-full mt-8 bg-accent rounded-full" style={styles.theme_bg}>
                                            <p onClick={handleBuyProduct} className="text-center text-white font-semibold text-lg py-2">Add to cart</p>    
                                        </button> 
                                    }    
                                    {userOwnsProduct &&
                                        <button className="w-full mt-8 bg-accent rounded-full" style={styles.theme_bg}>
                                            <p onClick={handleDownload} className="text-center text-white font-semibold text-lg py-2">Download</p>    
                                        </button> 
                                    }         
                                    
                                    {warning && <p className="mt-2 ml-6 w-full text-left text-orange-400">{warning}</p>}                      
                                    {error && <p className="mt-2 ml-6 w-full text-left text-red-500">{error}</p>}                      
                                    {success && <p className="mt-2 ml-6 w-full text-left text-green-500">{success}</p>} 
                   
                                </div>

                                <div className="container mx-auto flex flex-row mt-36 mb-4">
                                    <table className="">
                                        <tbody className="">
                                            <tr className="">
                                                <td className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
                                                    </svg>
                                                </td>
                                                <td className="">
                                                    <button onClick={handleReportProduct} className="">
                                                        <p className="ml-2">Report product</p>
                                                    </button>

                                                    {isReportingProduct && (
                                                        <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
                                                            <div className="w-full md:w-3/5 xl:w-2/5 h-2/3 md:h-1/2 mt-32 rounded-xl bg-white m-auto z-50">
                                                                <div className="m-auto text-center container pt-4">
                                                                    <div className="w-full">
                                                                        <table className="w-full">
                                                                            <tbody className="w-full">
                                                                                <tr className="w-full">
                                                                                    <td className="w-full">
                                                                                        <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Report product</p>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <button onClick={handleReportProduct} className="fixed -mt-4">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                                                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="w-2/3 mx-auto mt-10">
                                                                    <div className="text-left">
                                                                        <p className="ml-6 mb-1">Message</p>
                                                                        <input onChange={(e) => { setReportMessage(e.target.value) }} type="text" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} />
                                                                        
                                                                        <button onClick={reportProduct} className="rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-8" style={styles.theme_bg}>
                                                                            <p className="w-full text-white font-bold">Send report</p>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>  

                                <Footer />
                            </div>      
                        )
                    } else {
                        // Loading
                        // Look at tailwindcss -> animation -> animate-pulse 
                    }
                } else {
                    return (
                        <div className="flex flex-col min-h-screen">
                            <Header user={user} role={role} store_name={store_name} amount_to_remove_to_buyer_balance={amountToRemoveToBuyerBalance} theme_color={store.theme_color} />

                            {searchParams.get('action') && searchParams.get('action') === 'login' && (
                                <Login theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                            )}
                            
                            {searchParams.get('action') && searchParams.get('action') === 'register' && (
                                <Register theme_color={store.theme_color} changeUser={setLoggedInOrRegistered} />
                            )}
                            
                            <div className="flex-1 mx-auto pt-20 md:pt-32 lg:pt-40 text-center container">
                                <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Products</p>

                                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {products && products.map((product, key) => {
                                        return (
                                            <Product product={product} key={key} theme_color={store.theme_color} />
                                        )
                                    })}
                                </div>

                                
                            </div>

                            <div className="container mx-auto flex flex-row mt-40 mb-4">
                                <table className="">
                                    <tbody className="">
                                        <tr className="">
                                            <td className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
                                                </svg>
                                            </td>
                                            <td className="">
                                                <button onClick={handleReportStore} className="">
                                                    <p className="ml-2">Report store</p>
                                                </button>

                                                {isReportingStore && (
                                                    <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
                                                        <div className="w-full md:w-3/5 xl:w-2/5 h-2/3 md:h-1/2 mt-32 rounded-xl bg-white m-auto z-50">
                                                            <div className="m-auto text-center container pt-4">
                                                                <div className="w-full">
                                                                    <table className="w-full">
                                                                        <tbody className="w-full">
                                                                            <tr className="w-full">
                                                                                <td className="w-full">
                                                                                    <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Report Store</p>
                                                                                </td>
                                                                                <td className="">
                                                                                    <button onClick={handleReportStore} className="fixed -mt-4">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="w-2/3 mx-auto mt-10">
                                                                <div className="text-left">
                                                                    <p className="ml-6 mb-1">Message</p>
                                                                    <input onChange={(e) => { setReportMessage(e.target.value) }} type="text" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} />
                                                                    
                                                                    <button onClick={reportStore} className="rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-8" style={styles.theme_bg}>
                                                                        <p className="w-full text-white font-bold">Send report</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <Footer />
                        </div>
                    )
                }  
            }
        } else {
            // Loading
            // Look at tailwindcss -> animation -> animate-pulse 
        }
    } 
    
}

export default Webshop