import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { PAYMENTS_URL, USERS_URL } from '../../configs/api/api.config'

import Error from "../states/ErrorOnTop"
import Warning from "../states/WarningOnTop"
import Success from "../states/SuccessOnTop"
import QRCode from 'react-qr-code'

function Balance(props) {
  const newCookies = new Cookies()

  const [error, setError] = useState()
  const [warning, setWarning] = useState()
  const [success, setSuccess] = useState()

  const [page, setPage] = useState("deposit")

  const [transferTo, setTransferTo] = useState("Seller")
  const [transferToAmount, setTransferToAmount] = useState(5.00)
  const [withdrawAmount, setWithdrawAmount] = useState(20.00)
  const [buyerBalance, setBuyerBalance] = useState()
  const [sellerBalance, setSellerBalance] = useState()
  const [depositLink, setDepositLink] = useState()

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }

  function handleClose(){
    props.changeDisplay(false)
  }

  function startDeposit(){
    const user = getCookie()

    axios.get(PAYMENTS_URL + "deposit", {
      headers: {
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setDepositLink(response.data.link)
        } else {
          setError(response.data.message)
        }
      })
  }

  function getBalance(){
    const user = getCookie()

    axios.get(USERS_URL + "balance", {
      headers: {
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setBuyerBalance(response.data.data[0].buyer_balance)
          setSellerBalance(response.data.data[0].seller_balance)
        } else {
          setError(response.data.error)
        }
      })
  }

  useEffect(() => {
    getBalance()
  }, [])

  useEffect(() => {
    startDeposit()

    const interval = setInterval(() => {
      startDeposit()
    }, 60000)

    return () => clearInterval(interval);
  }, [])

  function handleChangeTransferTo(){
    if(transferTo === "Seller"){
      setTransferTo("Buyer")
    } else {
      setTransferTo("Seller")
    }
  }

  function handleAmountTransferInput(e){
    setTransferToAmount(e.target.value)    
  }

  function handleAmountWithdrawInput(e){
    const amount = e.target.value  

    if(amount >= 20.00){
      setWithdrawAmount(amount)
      setWarning("")
    } else {
      setWithdrawAmount(amount)
      setWarning("A minimum of $20.00 is required.")
    }
  }

  function handleSubmitWithdraw(){
    const user = getCookie()

    if(withdrawAmount >= 20.00){
      axios.post(PAYMENTS_URL + "withdraw", {
        amount_to_withdraw: withdrawAmount * 100
      }, {        
        headers: {
          "x-access-token": user
        }
      })
        .then((response) => {          
          if(response.data.success){
            props.changeSellerBalance(response.data.data.seller_balance)
            setSellerBalance(response.data.data.seller_balance)

            setWarning("")
            setError("")
            setSuccess("The payout was successfull.")
          } else {
            setWarning("")
            setError(response.data.message)
          }
        })
    } else {
      setWarning("")
      setError("A minimum of $20.00 is required.")
    }
  }


  function handleSubmitTransfer(){  
    const user = getCookie()

    if(transferTo === "Seller"){
        const transfer_to = "seller"

        axios.post(PAYMENTS_URL + "transfer", {
          amount_to_transfer: transferToAmount,
          to_balance: transfer_to,          
        }, {          
          headers: {
            "x-access-token": user
          }
        })
          .then((response) => {
            if(response.data.success){
              props.changeBuyerBalance(response.data.data.buyer_balance)
              props.changeSellerBalance(response.data.data.seller_balance)
              setBuyerBalance(response.data.data.buyer_balance)
              setSellerBalance(response.data.data.seller_balance)

              setSuccess("The transfer was successfull.")
            } else {
              setError(response.data.message)
            }
          })
    } else {      
        const transfer_to = "buyer"

        axios.post(PAYMENTS_URL + "transfer", {
          amount_to_transfer: transferToAmount,
          to_balance: transfer_to,          
        }, {          
          headers: {
            "x-access-token": user
          }
        })
          .then((response) => {
            if(response.data.success){
              props.changeBuyerBalance(response.data.data.buyer_balance)
              props.changeSellerBalance(response.data.data.seller_balance)
              setBuyerBalance(response.data.data.buyer_balance)
              setSellerBalance(response.data.data.seller_balance)
              
              setSuccess("The transfer was successfull.")
            } else {
              setError(response.data.message)
            }
          })
    }
  }
  
  
  return (
    // bg
    <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
      {/* white card */}
      <div className="w-full md:w-3/5 xl:w-2/5 mt-32 rounded-xl bg-white m-auto z-50">
        {/* Title + close */}
        <div className="flex flex-row">
          <p className="w-1/12"></p>

          <p className="mx-auto pt-6 text-center font-bold text-2xl">Balance</p>

          <button onClick={handleClose} className="w-1/12 pt-6 text-center font-bold text-2xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
          </button>
        </div>

        {/* Page navigation */}
        <div className="flex flex-row justify-between w-3/4 mx-auto mt-3">
          {page === "deposit" && (
            <button className="px-4 w-1/3 text-center py-1 border border-accent rounded-t-xl bg-accent/10">
              <p className="">Deposit</p>
            </button>
          )}
          {page !== "deposit" && (
            <button onClick={() => { setPage("deposit"); setError(""); setSuccess(""); setWarning("") }} className="px-4 w-1/3 text-center py-1">
              <p className="">Deposit</p>
            </button>
          )}

          {page === "transfer" && (
            <button className="px-4 w-1/3 text-center py-1 border border-accent rounded-t-xl bg-accent/10">
              <p className="">Transfer</p>
            </button>
          )}
          {page !== "transfer" && (
            <button onClick={() => { setPage("transfer"); setError(""); setSuccess(""); setWarning("")  }} className="px-4 w-1/3 text-center py-1">
              <p className="">Transfer</p>
            </button>
          )}

          {page === "withdraw" && (
            <button className="px-4 w-1/3 text-center py-1 border border-accent rounded-t-xl bg-accent/10">
              <p className="">Withdraw</p>
            </button>
          )}
          {page !== "withdraw" && (
            <button onClick={() => { setPage("withdraw"); setError(""); setSuccess(""); setWarning("")  }} className="px-4 w-1/3 text-center py-1">
              <p className="">Withdraw</p>
            </button>
          )}

        </div>

        <hr className="w-3/4 mx-auto border-accent" />

        <div className="w-3/4 mx-auto mt-1">
          {error && ( <Error message={error} changeMessage={setError} /> )}
          {warning && ( <Warning message={warning} changeMessage={setWarning} /> )}
          {success && ( <Success message={success} changeMessage={setSuccess} /> )}          
        </div>
          

        <div className="mt-6">
          {/* Pages */}
          {/* depost */}
          {page === "deposit" && (
            <div className="w-3/4 mx-auto">
              <div className="text-center">
                {/* <img src={QR_CODE} alt="" className="w-1/3 mx-auto aspect-square" /> */}
                {depositLink && <QRCode value={depositLink} className="scale-90 mx-auto" />}
                <div className="font-medium text-lg">
                  <p className="">Scan the QR code to deposit</p>
                  <a className="text-blue-800" target={"_blank"} href={depositLink} rel="noreferrer">
                    or click here
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* transfer */}
          {page === "transfer" && (
            <div className="w-3/4 mx-auto">
              <div className="flex flex-row">
                <table className="w-full">
                  <tbody className="w-full">
                    <tr className="w-full">
                      <td className="">
                        <p className="w-full pl-6 text-accent text-sm ">FROM</p>
                        <div type="text" className="w-full bg-accent/5 border border-accent rounded-full px-4 py-2">
                          <p className="text-center text-lg">
                            {transferTo === "Seller" && ("Buyer")}
                            {transferTo === "Buyer" && ("Seller")}
                          </p>
                        </div>
                      </td>
                      <td className="w-1/12 mx-auto ">
                        <button className="w-full mt-2 mx-auto flex justify-center align-middle " onClick={handleChangeTransferTo}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                          </svg>
                        </button>
                      </td>
                      <td className="">
                        <p className="w-full pl-6 text-accent text-sm ">TO</p>
                        <div type="text" className="w-full bg-accent/5 border border-accent rounded-full px-4 py-2">
                          <p className="text-center text-lg">
                            {transferTo}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          
              <div className="mt-4">
                <p className="w-full pl-6 text-accent text-sm ">AMOUNT</p>
                <div className="w-full bg-accent/5 border border-accent rounded-full px-4 py-2 flex flex-row">
                  <table className="w-full">
                    <tbody className="w-full">
                      <tr className="w-full">
                        <td className="w-[16px]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                          </svg>
                        </td>
                        <td className="">
                          {transferTo === "Seller" && (
                            <input type="text" onChange={handleAmountTransferInput} placeholder={"e.g $5.00"} className="w-full bg-transparent focus:outline-none" />
                          )}
                          {transferTo !== "Seller" && (
                            <input type="text" onChange={handleAmountTransferInput} placeholder={"e.g $5.00"} className="w-full bg-transparent focus:outline-none" />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button onClick={handleSubmitTransfer} className="w-full bg-accent rounded-full mt-4 px-4 py-2 text-white font-bold text-lg">Transfer</button>
          
              <p className="w-full pt-2 pb-1 text-accent text-sm "><a href="/transfer-documentation-link">Find more about <span className="underline underline-offset-1">transfers</span></a></p>
            </div>
          )}
          {/* Payout/withdraw */}
          {page === "withdraw" && (
            <div className="w-3/4 mx-auto">
              <p className="w-full pl-6 text-accent text-sm ">Maximum withdrawable</p>
              <div type="text" className="w-full bg-white border border-accent rounded-full px-4 py-2">
                <p className="text-center text-lg">          
                  {sellerBalance !== 0 && (
                      "$" + sellerBalance / 100
                  )}
                  {sellerBalance === 0 && (
                      "$0.00"
                  )}  
                </p>
              </div>

          
              <div className="mt-4">
                <p className="w-full pl-6 text-accent text-sm ">Amount to withdraw</p>
                <div className="w-full bg-accent/5 border border-accent rounded-full px-4 py-2 flex flex-row">
                  <table className="w-full">
                    <tbody className="w-full">
                      <tr className="w-full">
                        <td className="w-[16px]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                          </svg>
                        </td>
                        <td className="">
                          <input type="text" onChange={handleAmountWithdrawInput} placeholder={"A minimum of $20.00 is required"} className="w-full bg-transparent focus:outline-none" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button onClick={handleSubmitWithdraw} className="w-full bg-accent rounded-full mt-4 px-4 py-2 text-white font-bold text-lg">Withdraw</button>
              <p className="w-full pt-2 pb-1 text-accent text-sm "><a href="/transfer-documentation-link">Find more about <span className="underline underline-offset-1">payouts</span></a></p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Balance