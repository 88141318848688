import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { WEBSITE_URL } from '../../configs/Website.config';

const CheckoutForm = ({ order_id }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: WEBSITE_URL + 'checkout/complete?order_id=' + order_id,
      },
    });


    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <PaymentElement />
      <button disabled={!stripe} onClick={handleSubmit} className="w-full mt-6 p-2 rounded-xl bg-accent text-white font-bold">Checkout</button>
      
      {errorMessage && <p className='mt-4 text-red-400'>{errorMessage}</p>}
    </div>
  )
};

export default CheckoutForm;