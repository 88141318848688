import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import NotFoundSVG from "../assets/svg/not_found.svg"
import { AUTHENTICATION_URL, USERS_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import sha256 from 'sha256'
import validator from "validator";

import Error from '../components/states/Error'
import Warning from '../components/states/Warning'
import Success from '../components/states/Success'
import scrollToTop from '../hooks/scrollToTop'



function NotFound() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [warning, setWarning] = useState("")

  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [newPassword, setNewPassword] = useState()
  const [passwordIsStrong, setPasswordIsStrong] = useState("")
  const [repeatPassword, setRepeatPassword] = useState()

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  function handleFirstSectionSave(){
    const user = getCookie()

    if(!username && !email){
      setError("Enter an username or email to change.")
      setSuccess("")
      scrollToTop()
      return
    }

    if(email && !validator.isEmail(email)){
      setError("A valid email should be entered.")
      setSuccess("")
      scrollToTop()
      return
    }

    axios.patch(USERS_URL + "save-account-settings", {
      username: username,
      email: email
    }, {
      headers: {
        'x-access-token': user
      }
    })
      .then((response) => {
        if(response.data.success){
          setError("")
          setSuccess(response.data.message)
        } else {
          setError(response.data.message)
          setSuccess("")
        }
        scrollToTop()
      })
  } 

  function handleNewPasswordSave(){
    const user = getCookie()

    if(!newPassword || !repeatPassword){
      setError("Enter a new password.")
      setSuccess("")
      scrollToTop()
      return
    }

    if(newPassword !== repeatPassword){
      setError("The repeated password should be the same.")
      setSuccess("")
      scrollToTop()
      return
    }

    if(passwordIsStrong !== ""){
      setError(passwordIsStrong)
      setSuccess("")
      scrollToTop()
      return
    }

    axios.patch(USERS_URL + "new-password", {
      newPassword: newPassword,
      repeatPassword: repeatPassword
    }, {
      headers: {
        'x-access-token': user
      }
    })
      .then((response) => {
        if(response.data.success){
          setSuccess(response.data.message)
          setError("")
        } else {
          setError(response.data.message)
          setSuccess("")
        }
        scrollToTop()
      })
  }

  function handlePasswordInput(e){
    setNewPassword(sha256(e.target.value))
    
    if(!validator.isStrongPassword(e.target.value, {minSymbols: 0})){
      if(e.target.value.length < 8){
        setPasswordIsStrong("The password should be minimum 8 characters long.")
      } else if(e.target.value.toUpperCase() === e.target.value){
        setPasswordIsStrong("The password should be contain a minimum of 1 lowercase character.")
      } else if(e.target.value.toLowerCase() === e.target.value){
        setPasswordIsStrong("The password should be contain a minimum of 1 uppercase character.")
      } else {
        setPasswordIsStrong("The password should be contain a minimum of 1 number.")
      }
    } else {
      setPasswordIsStrong("")
    }
  }

  return (
    <div className=''>
      <Header user={user} role={role} />

        {error && (<Error message={error} changeMessage={setError} />)}
        {warning && (<Warning message={warning} changeMessage={setWarning} />)}
        {success && (<Success message={success} changeMessage={setSuccess} />)}


        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Settings</p>

            <div className="w-1/2 mx-auto mt-8">
              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Username</p>
                <input onChange={(e) => {setUsername(e.target.value)}} type="text" name="" id='' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
              </div>

              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Email</p>
                <input onChange={(e) => {setEmail(e.target.value)}} type="email" name="" id='' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
              </div>

              <button onClick={handleFirstSectionSave} className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                <p className="w-full text-white font-bold">Save</p>
              </button>

              <div className="text-left mt-8">
                <p className="mb-1 font-medium text-base md:text-lg lg:text-xl">Change password</p>
              </div>

              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Password</p>
                <input onChange={handlePasswordInput} type="password" name="" id='' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
              </div>

              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Repeat password</p>
                <input onChange={(e) => {setRepeatPassword(sha256(e.target.value))}} type="password" name="" id='' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
              </div>

              <button onClick={handleNewPasswordSave} className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                <p className="w-full text-white font-bold">Save</p>
              </button>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default NotFound