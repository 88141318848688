import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL, STORES_URL, USERS_URL } from '../../../configs/api/api.config'
import scrollToTop from '../../../hooks/scrollToTop'
import Error from '../../states/ErrorOnTop'
import Header from './Header'

function CreateStore() {
  const newCookies = new Cookies()
  const { store } = useParams()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [role, setRole] = useState("login")

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const [error, setError] = useState("")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)

            axios.get(USERS_URL, {
              headers: {
                'x-access-token': user
              }
            })
              .then((response) => {
                if(response.data.success){
                  setUserInfo(response.data.data[0]) 
                } else {
                  console.log("User info could not be retrieved.")
                }
              })
          } else {
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }     
  }

  useEffect(() => {
    getUser()
  }, [])

  function handleCreate(){
    const user = getCookie()
    
    axios.post(STORES_URL, {
      name: name,
      description: description
    }, {
      headers: {
        'x-access-token': user
      }
    })
      .then((response) => {
        if(response.data.success){
          navigate("/seller/0")
        } else {
          setError(response.data.message)
          scrollToTop()
        }
      })

  }

  return (
    user && (
      <div>
        <Header user={user} role={role} user_info={userInfo} changeError={setError} />

        {error && ( <Error message={error} changeMessage={setError} />)}
        
        <div className="container mx-auto">  
          <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6 pt-6 md:mt-0">Create Store</p>

          <div className="w-full md:w-1/2 mx-auto">
            <div className="text-left mt-5">
              <p className="ml-6 mb-1">Name Store</p>
              <input onChange={(e) => { setName(e.target.value)} } type="text" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
            </div>
            <div className="text-left mt-5">
              <p className="ml-6 mb-1">Description</p>
              <input onChange={(e) => { setDescription(e.target.value)} } type="text" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
            </div>
            <button onClick={handleCreate} className="rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
              <p className="w-full text-white font-bold">Start Selling</p>
            </button>
          </div>
        </div>

      </div>
    )
  )
}

export default CreateStore