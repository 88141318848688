import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL, SEARCH_URL, STORES_URL, USERS_URL } from '../../../configs/api/api.config'
import { WEBSITE_URL } from '../../../configs/Website.config'
import Error from '../../states/ErrorDashboard'
import Success from '../../states/SuccessOnTop'
import Header from './Header'
import Item from './components/SearchItem';
import CreateProduct from './components/CreateProduct'
import scrollToTop from '../../../hooks/scrollToTop'

function Dashboard() {  
  const newCookies = new Cookies()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const { store } = useParams()

  const [user, setUser] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [role, setRole] = useState("login")

  const [currentStore, setCurrentStore] = useState()
  const [products, setProducts] = useState()

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")


  const [keywords, setKeywords] = useState("")
  const [sortBy, setSortBy] = useState("")
  const [foundProducts, setFoundProducts] = useState([])
  const [noProductsFound, setNoProductsFound] = useState(false)

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  
  
  useEffect(() => {
    const user = getCookie()

    setKeywords(searchParams.get("keywords"))

    if(currentStore){
      axios.get(SEARCH_URL + "from-store?keywords=" + searchParams.get("keywords") + "&store_id=" + currentStore.store_id, {
        headers: {
          "x-access-token": user
        }
      })
        .then((response) => {
          if(response.data.success){
            setFoundProducts(response.data.data)
            setNoProductsFound(false)
          } else if(response.data.message === "No products where found."){
            setNoProductsFound(true)
          }
        })
    }
  }, [searchParams, currentStore])

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)            
          } else {
            navigate("/login")
          }
        })

      axios.get(USERS_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUserInfo(response.data.data[0]) 
          } else {
            console.log("User info could not be retrieved.")
          }
        })

      getCurrentStore()
    } else {
      navigate("/login")
    }     
  }

  function getCurrentStore(){
    const user = getCookie()

    axios.get(STORES_URL + "user?store=" + store, {
        headers: {
            "x-access-token": user
        }
    })   
        .then((response) => {
            if(response.data.success){
                if(response.data.has_stores){
                    setCurrentStore(response.data.data.store[0])
                } else {
                    navigate("/seller/create")
                }
            } else {
              setError(response.data.message)
              setSuccess("")
              scrollToTop()
            }
        })   
  }

  useEffect(() => {
    getUser()
  }, [])

  function handleSearchInput(e){
    setSearchParams({"page": "products", "keywords": e.target.value})
  }

  function handleSearchEnter(e){
    if(e.key === "Enter"){
      setSearchParams({"page": "products", "keywords": keywords})
    }
  }

  function handleSearchClick(){
    setSearchParams({"page": "products", "keywords": keywords})
  }

  function handleCreate(){
    setSearchParams({"page": "products", "keywords": keywords, "action": "create"})
  }

  return (
    user && (
      <div className='min-h-screen'>
        <div className="fixed bg-white w-full">
          <Header user={user} role={role} user_info={userInfo} changeError={setError} />
        </div>

        {currentStore && searchParams.get("action") && searchParams.get("action") === "create" && (
          <CreateProduct store_id={currentStore.store_id} />
        )}
        
        <div className="pt-24">   
          {error && (<Error message={error} changeMessage={setError} />)}  
          {success && (<Success message={success} changeMessage={setSuccess} />)}  

          <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6 pt-6 md:mt-0">Products</p>

          <div className="container w-full md:w-2/3 lg:w-1/2 mx-auto">
            
            <div className="flex flex-row mt-8">
              <div className=" border-accent border rounded-full px-6 py-2 w-full">
                <table className="w-full">
                  <tbody className="w-full">
                    <tr className="w-full flex flex-row justify-between">
                      <td className="align-middle w-full">
                        <input type="text" onChange={handleSearchInput} onKeyDown={handleSearchEnter} name="" className="focus:outline-0 w-full" />
                      </td>
                      <td className="align-middle">
                        <button className="" onClick={handleSearchClick}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search text-accent" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button className="ml-4 py-2 px-8 bg-accent text-white rounded-full font-semibold" onClick={handleCreate}>
                <p className="">Create</p>
              </button>
            </div>

            
            {/* Result of search */}
            <div className="mt-10">
              {currentStore && foundProducts && foundProducts.map((item, key) => {
                return (
                  <Item item={item} key={key} store_name={currentStore.name} currentStore={currentStore} changeFoundProducts={setFoundProducts} changeNoFoundProducts={setNoProductsFound} changeError={setError} changeSuccess={setSuccess} />
                )
              })}
            </div>

            {/* No products found */}
            {noProductsFound && (
              <div className="md:w-3/4 lg:w-1/2 mx-auto">
                <p className="font-semibold ">No more products where found...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default Dashboard