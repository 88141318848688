import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';

import ShoppingCartItem from '../components/checkout/ShoppingCartItem'
import Header from '../components/Header'

import { useStripe, Elements, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { PAYMENTS_URL } from '../configs/api/api.config';
import CheckoutForm from '../components/checkout/CheckoutForm';
import Cookies from 'universal-cookie';
import Confirm from '../components/checkout/Confirm';

const stripePromise = loadStripe('pk_test_51LIdHtH3QBEiJL1npXaCwfiTndcpEaFoCRA1hSt9uFC20m5rih6ob1yx1PLEBvEbQAeOs4qM7Ng3JsQajZn3pdBb002Y0nIYHg', { locale: 'en' });

const newCookies = new Cookies()

function Checkout() {
    const [searchParams, setSearchParams] = useSearchParams();

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
    }  


    if(searchParams.get('payment_intent_client_secret')){
        const options = {
                clientSecret: searchParams.get('payment_intent_client_secret'),
                appearance: {
                    
                },
        };

        return (
            <div className="">
                <Header />

                <div className='container mx-auto pt-10 md:pt-16 lg:pt-20'>
                    <p className="font-extrabold text-xl md:text-2xl lg:text-3xl text-center">Checkout</p>

                    <Elements stripe={stripePromise} options={options}>
                        <Confirm />
                    </Elements>
                </div>
            </div>
        )
    }
}

export default Checkout