import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { USERS_URL } from '../../../../configs/api/api.config'
import Cookies from 'universal-cookie'
import Item from './TransactionItem'
import ProductItem from './ProductItem'

function Transactions({user, role, user_info, setError}) {
  const newCookies = new Cookies()

  const [buyTransactions, setBuyTransactions] = useState({})
  const [transferTransactions, setTransferTransactions] = useState({})
  const [depositTransactions, setDepositTransactions] = useState({})
  const [payoutTransactions, setPayoutTransactions] = useState({})

  const [typeTransaction, setTypeTransaction] = useState("buy")

  const [message, setMessage] = useState("")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function handleChooseType(e){
    setTypeTransaction(e.target.value)
  }

  useEffect(() => {
    const user = getCookie()

    axios.get(USERS_URL + "transactions", {
      headers: {
        'x-access-token': user
      }
    })
      .then((response) => {
        if(response.data.success){
          setBuyTransactions(response.data.data.bought)
          // setTransferTransactions(response.data.data.transfers)
          // setDepositTransactions(response.data.data.deposits)
          setPayoutTransactions(response.data.data.payouts)
        } else {
          setMessage(response.data.message)
        }
      })
  }, [])

  return (
    <div>
      <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6 pt-6 md:mt-0">Transactions</p>
      <div className="flex flex-row">
        <table className="">
          <tbody className="">
            <tr className="">
              <td className="">
                <p className="font-bold">Type: </p>
              </td>

              <td className="">
                <select name="" id="" className='ml-2' onChange={handleChooseType}>
                  <option value="buy">
                    Bought items
                  </option>
                  {/* <option value="transfer">
                    Transfers
                  </option>
                  <option value="deposit">
                    Deposits
                  </option> */}
                  <option value="payout">
                    Payouts
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      {message && (
        <div className="w-full mt-4">
          <p className="text-center text-xl">{message}</p>
        </div>
      )}

      <div className="pt-4">
        { typeTransaction === "buy" && (buyTransactions.length > 0 && (buyTransactions.map((item, key) => {
          return <ProductItem item={item} key={key} />
        })))}

        { typeTransaction === "buy" && (buyTransactions.length === 0 && (
          <div className="w-full mt-4">
            <p className="text-center text-xl">You do not have any purchase transactions yet.</p>
          </div>
        ))}


        {/* { typeTransaction === "transfer" && (transferTransactions.length > 0 && (transferTransactions.map((item, key) => {
          return <Item item={item} key={key} />
        })))}

        { typeTransaction === "transfer" && (transferTransactions.length === 0 && (
          <div className="w-full mt-4">
            <p className="text-center text-xl">You do not have any transfer transactions yet.</p>
          </div>
        ))}


        { typeTransaction === "deposit" && (depositTransactions.length > 0 && (depositTransactions.map((item, key) => {
          return <Item item={item} key={key} />
        })))}

        { typeTransaction === "deposit" && (depositTransactions.length === 0 && (
          <div className="w-full mt-4">
            <p className="text-center text-xl">You do not have any deposit transactions yet.</p>
          </div>
        ))} */}


        { typeTransaction === "payout" && (payoutTransactions.length > 0 && (payoutTransactions.map((item, key) => {
          return <Item item={item} key={key} />
        })))}

        { typeTransaction === "payout" && (payoutTransactions.length === 0 && (
          <div className="w-full mt-4">
            <p className="text-center text-xl">You do not have any payout transactions yet.</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Transactions