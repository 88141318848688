import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AUTHENTICATION_URL, STORES_URL, USERS_URL } from '../../../configs/api/api.config'
import { WEBSITE_URL } from '../../../configs/Website.config'
import scrollToTop from '../../../hooks/scrollToTop'
import Error from '../../states/ErrorDashboard'
import Success from '../../states/SuccessOnTop'
import Header from './Header'

function Dashboard() {  
  const newCookies = new Cookies()
  const navigate = useNavigate()
  const { store } = useParams()

  const [user, setUser] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [role, setRole] = useState("login")

  const [currentStore, setCurrentStore] = useState()
  const [storeName, setStoreName] = useState()
  const [description, setDescription] = useState()
  const [themeColor, setThemeColor] = useState()

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getCurrentStore(){
    const user = getCookie()

    axios.get(STORES_URL + "user?store=" + store, {
        headers: {
            "x-access-token": user
        }
    })   
        .then((response) => {
            if(response.data.success){
                if(response.data.has_stores){
                  setCurrentStore(response.data.data.store[0])
                } else {
                  navigate("/seller/create")
                }
            } else {
              setError(response.data.message)
              setSuccess("")
              scrollToTop()
            }
        })   
}

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)            
          } else {
            navigate("/login")
          }
        })

      axios.get(USERS_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUserInfo(response.data.data[0]) 
          } else {
            console.log("User info could not be retrieved.")
          }
        })

      getCurrentStore()
    } else {
      navigate("/login")
    }     
  }

  useEffect(() => {
    getUser()
  }, [])

  function copyYourStoreLink(){
    navigator.clipboard.writeText(encodeURI(WEBSITE_URL + "s/" + currentStore.name))
  }

  function openStore(){
    window.open(WEBSITE_URL + "s/" + currentStore.name)
  }

  function handleSave(){
    const user = getCookie()

    axios.patch(STORES_URL + "?store_id=" + currentStore.store_id, {
      store_name: storeName,
      description: description,
      theme_color: themeColor
    }, {
      headers: {
          "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setSuccess("The preferences are stored successfully ")
          setError("")

          if(storeName){
            getCurrentStore()
            document.getElementById("store_name").textContent = storeName
          }
        } else {
          setError(response.data.message)
          setSuccess("")
        }
      })
  }

  return (
    user && (
      <div className='min-h-screen'>
        <div className="fixed bg-white w-full">
          <Header user={user} role={role} user_info={userInfo} changeError={setError} />
        </div>
        
        <div className="pt-16">   
          {error && (<Error message={error} changeMessage={setError} />)}  
          {success && (<Success message={success} changeMessage={setSuccess} />)}  

          <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6 pt-6 md:mt-0">My Store</p>

          <div className="container w-full md:w-2/3 lg:w-1/2 mx-auto">
            <p className="font-semibold md:text-lg lg:text-xl text-left mb-2 pt-6 md:mt-0">Share your store</p>   
            {currentStore && (         
              <div className="w-full bg-accent/10 border border-accent/50 p-4 rounded-xl flex flex-row justify-between">
                <p className="inline align-middle">{encodeURI(WEBSITE_URL + "s/" + currentStore.name)}</p>
                <div className="">
                  <button onClick={copyYourStoreLink}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard inline align-middle m-1" viewBox="0 0 16 16">
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                  </button>
                  <button onClick={openStore}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short m-1 inline align-middle" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </button>
                </div>
              </div>
            )}

            <p className="font-semibold md:text-lg lg:text-xl text-left mb-2 pt-8 md:mt-0">Edit your store</p>

            <div className="">
              <label htmlFor="name">Store name</label>
              <div className='border-accent border rounded-xl flex flex-row px-6 mt-1 p-2'>
                <input type="text" name="" id="name" onChange={(e) => { setStoreName(e.target.value ) }} className="w-full focus:outline-none bg-transparent" />
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="Description" className="">Description</label>
              <div className='border-accent border rounded-xl flex flex-row px-6 mt-1 p-2'>
                <textarea name="" id="Description" onChange={(e) => { setDescription(e.target.value ) }} className='w-full focus:outline-0 max-h-[200px]' rows={3} maxLength={500}></textarea>
              </div>
            </div>

            <br />
    
            <div className="flex flex-row">
              <label htmlFor="color" className="mr-4">Theme color</label>
              <input type="color" name="" id="color" onChange={(e) => { setThemeColor(e.target.value ) }} className="" />
            </div>

            <button onClick={handleSave} className="bg-accent px-4 py-2 rounded-full mt-6 text-white font-semibold">Save changes</button>
          </div>

        </div>
      </div>
    )
  )
}

export default Dashboard