import React from 'react'
import { WEBSITE_URL } from '../../configs/Website.config';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Card({store}) {
    const max_length_mobile = 100;
    const max_length_desktop = 175;

    const { width } = useWindowDimensions()

    if(width > 425){
        if(store.description.length > max_length_desktop){
            store.description = store.description.substring(0, max_length_desktop) + "..."
        }
    } else {        
        if(store.description.length > max_length_mobile){
            store.description = store.description.substring(0, max_length_mobile) + "..."
        }
    }
    
  return (
    <a href={WEBSITE_URL + "s/" + encodeURI(store.name)}>
      <div className="bg-accent/20 rounded-xl w-5/6 sm:w-[400px] h-[200px] inline-block m-2 cursor-pointer hover:scale-105 ease-in-out duration-500 sm:text-left text-left">
        <p className="text-lg font-medium mx-5 mt-5 mb-2">{store.name}</p>
        <p className="text-accent mx-5 mb-2 h-[110px] whitespace-normal">{store.description}</p>
        <p className="text-xs font-normal mx-5">This store has {store.products} products</p>
      </div>
    </a>

  )
}

export default Card