import React from 'react'
import { useState } from 'react'

function FaqItem({item}) {
    const {question, answer} = item
    const [isOpen, setIsOpen] = useState(false)

    function handleClick() {
        if(isOpen){
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

  return (
    <div>
        <div className="bg-white rounded-xl text-left">
            <div className="bg-accent/20 h-full rounded-xl">
                <button onClick={handleClick} className="w-full">
                    <div className="ml-3 pt-2">
                        <table className="w-full">
                            <tbody className="w-full">
                                <tr className="w-full">
                                    <td className='align-middle'>
                                        <p className="text-xl text-left font-semibold">{question}</p>
                                    </td>
                                    <td className='align-middle text-right'>
                                      <svg xmlns="http://www.w3.org/2000/svg" className='ml-1 mr-2' width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                      </svg>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </button>
              
                {isOpen && (
                    <div className="">
                        <hr className='mb-2 border-accent/20' />

                        <p className="ml-3 pb-6 pr-2 text-left">{answer}</p>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default FaqItem