import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { USERS_URL } from '../../../../configs/api/api.config'
import Cookies from 'universal-cookie'
import { WEBSITE_URL } from '../../../../configs/Website.config'
import { Chart } from 'chart.js'
import scrollToTop from '../../../../hooks/scrollToTop'

function Referrals({user, role, user_info, setError}) {
  const newCookies = new Cookies()

  const [message, setMessage] = useState("")
  const [totalReferrals, setTotalReferrals] = useState()
  const [paid, setPaid] = useState()
  const [pending, setPending] = useState()
  const [yourCode, setYourCode] = useState()
  const [last7Days, setLast7Days] = useState()

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getPartner(){
    const user = getCookie()

    axios.get(USERS_URL + "partner", {
      headers: {
        'x-access-token': user
      }
    })
      .then((response) => {
        console.log(response)
        if(response.data.success){
          const data = response.data.data

          setYourCode(data.code)
          setTotalReferrals(data.total_referrals)
          setPaid(data.paid)
          setPending(data.pending)
          setLast7Days(data.last_7_days)
        }
      })
  }

  useEffect(() => {
    getPartner()
  }, [])

  
  const ctx = 'weekChart'
  const date = new Date()

  const sevenDaysAgo = new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getFullYear() 
  const sixDaysAgo = new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (5 * 24 * 60 * 60 * 1000)).getFullYear() 
  const fiveDaysAgo = new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (4 * 24 * 60 * 60 * 1000)).getFullYear()   
  const fourDaysAgo = new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (3 * 24 * 60 * 60 * 1000)).getFullYear()   
  const threeDaysAgo = new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000)).getFullYear()    
  const twoDaysAgo = new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getDate() + "-" + (new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getMonth() + 1) + "-" + new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000)).getFullYear()   
  const oneDaysAgo = new Date(date.getTime()).getDate() + "-" + (new Date(date.getTime()).getMonth() + 1) + "-" + new Date(date.getTime()).getFullYear()   

  const [datesLast7Days, setDatesLast7Days] = useState({sevenDaysAgo, sixDaysAgo, fiveDaysAgo, fourDaysAgo, threeDaysAgo, twoDaysAgo, oneDaysAgo})

  useEffect(() => {
    if(last7Days){
      if(document.getElementById(ctx) && last7Days.length > 0 ){
        if(!Chart.getChart(ctx)){
          console.log(last7Days)
          const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [datesLast7Days.sevenDaysAgo, datesLast7Days.sixDaysAgo, datesLast7Days.fiveDaysAgo, datesLast7Days.fourDaysAgo, datesLast7Days.threeDaysAgo, datesLast7Days.twoDaysAgo, datesLast7Days.oneDaysAgo],
                datasets: [{
                    label: 'Total referrals',
                    data: [last7Days[0], last7Days[1], last7Days[2], last7Days[3], last7Days[4], last7Days[5], last7Days[6]],
                    backgroundColor: 'rgba(87, 105, 171, 100)',
                    borderColor: 'rgba(87, 105, 171, 100)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
          })
        }
      }
    }
  }, [document.getElementById(ctx), last7Days])

  function copyYourCode(){
    navigator.clipboard.writeText(WEBSITE_URL + "?ref=" + yourCode)
  }

  return (
    <div>
      {message && (
        <div className="w-full mt-20">
          <p className="text-center text-2xl font-bold">{message}</p>
        </div>
      )}

      <div className="pt-4">
        <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6">Partners</p>

        <div className="w-full">               
          <div className="flex flex-row w-full mb-8">
            <div className="w-full md:w-2/3 lg:w-1/2 mr-4">
              <p className="font-semibold md:text-lg lg:text-xl text-left mb-2">Your referral link</p>
              
              <div className="w-full bg-accent/10 border border-accent/50 p-4 rounded-xl">
                <table className="">
                  <tbody className="">
                    <tr className="">
                      <td className="w-full">
                        <p className="">{WEBSITE_URL + "?ref=" + yourCode}</p>
                      </td>
                      <td className="">
                        <button onClick={copyYourCode}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* <div className="w-1/2 ml-4">
              <p className="font-semibold md:text-lg lg:text-xl text-left mb-2">Enter a referral code</p>
              
              <div className="w-full bg-accent/10 border border-accent/50 p-4 rounded-xl">
                <input type="text" className='bg-transparent focus:outline-0' name="" id="" />
              </div>
            </div> */}
          </div>
        </div>

        <div className="">                  
          <p className="font-semibold md:text-lg lg:text-xl text-left mb-2 pt-6 md:mt-0">Statistics</p>
  
          <div className="flex flex-row">
            <div className="w-full bg-accent/10 border border-accent/50 mb-2 mr-4 p-4 rounded-xl min-h-[100px]">
              <p className="text-sm mb-2">Total</p>
              <p className="text-2xl text-center lg:text-left">{totalReferrals}</p>
            </div>
            {/* <div className="w-1/2 bg-accent/10 border border-accent/50 mb-2 ml-4  p-4 rounded-xl min-h-[100px]">
              <p className="text-sm mb-2">Paid</p>
              <p className="text-2xl text-center lg:text-left">
                {paid !== 0 && (
                    "$" + paid / 100
                )}
                {paid === 0 && (
                    "$0.00"
                )}
              </p>
            </div> */}
          </div>
          <div className="flex flex-row">
            <div className="w-1/2 bg-accent/10 border border-accent/50 mt-2 mr-4  p-4 rounded-xl min-h-[100px]">
              <p className="text-sm mb-2">Paid</p>
              <p className="text-2xl text-center lg:text-left">
                {paid !== 0 && (
                    "$" + paid / 100
                )}
                {paid === 0 && (
                    "$0.00"
                )}
              </p>
            </div>
            <div className="w-1/2 bg-accent/10 border border-accent/50 mt-2 ml-4 p-4 rounded-xl min-h-[100px]">
              <p className="text-sm mb-2">Pending</p>
              <p className="text-2xl text-center lg:text-left">
                {pending !== 0 && (
                    "$" + pending / 100
                )}
                {pending === 0 && (
                    "$0.00"
                )}
              </p>
            </div>
          </div>
                
          <canvas id="weekChart" className='w-full max-h-[500px] mt-10'></canvas>
        </div>
      </div>
    </div>
  )
}

export default Referrals