module.exports = {
    steps_heading: "Sell",
    steps_subheading: "all your ROBLOX products in your own webshop and access all our tools to increase your earnings.",
    steps: [
        {
            step_number: 1,
            step_description: "Sign up and go to the dashboard"
        }, 
        {
            step_number: 2,
            step_description: "Create a new store and fill in the details"
        }, 
        {
            step_number: 3,
            step_description: "Add items to your store and set a price"
        }, 
        {
            step_number: 4,
            step_description: "Start selling your products and earning money for your hard work."
        }, 
    ]
}