import React from 'react'

function Footer() {
  return (
    <div className='w-full'>
        <hr />
        <div className="container mx-auto flex flex-row justify-between">
            <p className="text-sm mt-2 ml-2">This webshop is powered by <a href="/"><span className="font-black"><span className="text-accent">Ro</span>Shop</span></a></p>

            <div className="mt-2 flex flex-row">
                <a href="/tos">
                    <p className="text-sm mr-2 ">Terms of Service</p>
                </a>
                <a href="/privacy">
                    <p className="text-sm ml-2">Privacy Policy</p>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Footer