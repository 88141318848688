import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AUTHENTICATION_URL, USERS_URL } from '../../../configs/api/api.config'
import RoShop from "../../../assets/images/RoShop.png"
import Cookies from 'universal-cookie'

import BalancePopup from "../../popups/Balance"

function Header({ user, role, user_info }) {
    const newCookies = new Cookies()
    const navigate = useNavigate()

    if(user === 0){
        navigate("/login")
    }

    const [isNavOpenMobile, setIsNavOpenMobile] = useState(false)
    const [keywords, setKeywords] = useState("")
    
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false)
    const [isBalancePopupOpen, setIsBalancePopupOpen] = useState(false)

    const [buyerBalance, setBuyerBalance] = useState(user_info.buyer_balance)
    const [sellerBalance, setSellerBalance] = useState(user_info.seller_balance)
    

    function handleMenu(){
        if(isNavOpenMobile){
            setIsNavOpenMobile(false)
        } else {
            setIsNavOpenMobile(true)
        }
    }

    function handleSearchInput(e){
        setKeywords(e.target.value)
    }

    function handleSearchEnter(e){
        if(e.key === "Enter"){
            navigate("/search?keywords=" + keywords + "&sort-by=popular&page=1")
        }
    }

    function handleSearchClick(){
        navigate("/search?keywords=" + keywords + "&sort-by=popular&page=1")
    }

    function handleAccountMenu(){
        if(isAccountMenuOpen){
            setIsAccountMenuOpen(false)
        } else {
            setIsAccountMenuOpen(true)
        }
    }

    function handleLogOut(){
        newCookies.remove("user", {path: "/"})
        window.location.reload(false);
    }

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
      }  
    
    function handleBalanceDisplay(){
        setIsBalancePopupOpen(true)
    }

    useEffect(() => {
        setBuyerBalance(user_info.buyer_balance)
        setSellerBalance(user_info.seller_balance)
    }, [user_info])

    

  return (
    <div className='bg-white w-full relative z-30 border-b'>
        {/* {isBalancePopupOpen && (
            <BalancePopup changeDisplay={setIsBalancePopupOpen} changeBuyerBalance={setBuyerBalance} changeSellerBalance={setSellerBalance} />
        )} */}
        {/* 
            - Explore / Home
            - Search
            - Account
                - Bought products
                - Settings
            - Balance
        */}

        <div className="container bg-white flex flex-row justify-between mx-auto my-2">
            <div className="flex bg-white flex-row">
                <table>
                    <tbody>
                        <tr>
                            <td className="align-middle">
                                <div id="header_logo">
                                    <a href="/dashboard">
                                        <p className="text-2xl md:text-3xl font-black"><span className="text-accent">Ro</span>Shop</p>
                                        {/* <img src={RoShop} className="max-h-12" alt="logo" /> */}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                {/* <div id="header_navigation" className="lg:flex lg:flex-row hidden ml-6">
                    <table>
                        <tbody>
                            <tr>
                                <td className="align-middle">
                                    <div id="navigation_item" className='mx-2'>
                                        <a href="/explore">
                                            <p className='text-lg hover:border-b-2 border-accent'>Explore</p>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
            </div>

            <div className="flex flex-row">
                <div id="header_search" className="hidden lg:flex">
                    <div className=" border-accent border rounded-full flex flex-row px-6 mr-10">
                        <table className="">
                            <tbody className="">
                                <tr className="">
                                    <td className="align-middle">
                                        <input type="text" onChange={handleSearchInput} onKeyDown={handleSearchEnter} name="" className="focus:outline-0" />
                                    </td>
                                    <td className="align-middle">
                                        <button className="" onClick={handleSearchClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search text-accent" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="header_call_to_action" className='lg:flex lg:flex-row hidden'>
                    <table>
                        <tbody>
                            <tr>
                                {/* <td className="align-middle">
                                    <button className="" onClick={handleBalanceDisplay}>
                                        <div id="balance" className='mx-6 rounded-full border border-accent'>                                                                                      
                                            {buyerBalance >= 0 && (
                                                <p className='text-lg text-center px-4 py-1'>             
                                                    {buyerBalance !== 0 && (
                                                        "$" + buyerBalance / 100
                                                    )}
                                                    {buyerBalance === 0 && (
                                                        "$0.00"
                                                    )}  
                                                </p>
                                            )}
                                        </div>
                                    </button>
                                </td> */}
                                <td className="align-middle">
                                    <div className="">    
                                        <div id="cta_button_register" className='bg-accent rounded-full relative'>
                                            <button onClick={handleAccountMenu}>
                                                <p className='text-lg font-medium text-white py-1 px-4'>Account</p>
                                            </button>
                                        </div>

                                        {role !== "login" && ( isAccountMenuOpen && (
                                            <div className="mt-2 z-40 absolute">
                                                <div className="float-left text-center border bg-white border-accent rounded-lg">
                                                    <div className=" text-base py-1 px-4">
                                                        <a href="/dashboard">
                                                            <p className="">Dashboard</p>
                                                        </a>
                                                    </div>
    
                                                    <hr className="" />

                                                    <div className=" text-base py-1 px-4">
                                                        <a href="/seller/0">
                                                            <p className="">My shop</p>
                                                        </a>
                                                    </div>
    
                                                    <hr className="" />
    
                                                    <div className=" text-base py-1 px-4">
                                                        <a href="/settings">
                                                            <p className="">Settings</p>
                                                        </a>
                                                    </div>
    
                                                    <hr className="" />
    
                                                    <div className=" text-base py-1 px-4">
                                                        <button onClick={handleLogOut}>
                                                            <p className="text-red-400">Log out</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="phone_header_navigation" className="lg:hidden">
                <div id="menu_opener" className="h-full">
                    <table className="h-full">
                        <tbody className="h-full">
                            <tr className="h-full">
                                <td className="h-full">
                                    <button className="align-middle" onClick={handleMenu}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {isNavOpenMobile && (
                    <div id="phone_navigation" className='w-full h-full fixed left-0 bg-white'>
                        <div className="container mx-auto mt-5">
                            {/* <div className="text-left">
                                <a href="/features">
                                    <p className="text-lg font-medium px-6 py-4">Features</p>
                                </a>
                            </div>

                            <hr />

                            <div className="text-left">
                                <a href="/resources">
                                    <p className="text-lg font-medium px-6 py-4">Resources</p>
                                </a>
                            </div>

                            <hr />
                            
                            <div className="text-left">
                                <a href="/pricing">
                                    <p className="text-lg font-medium px-6 py-4">Pricing</p>
                                </a>
                            </div>

                            <hr />

                            <div className="text-left">
                                <a href="/explore">
                                    <p className="text-lg font-medium px-6 py-4">Explore</p>
                                </a>
                            </div>

                            <hr /> */}

                            <div className="flex flex-row text-center mx-6 my-4 pt-10">
                                <table className="w-full">
                                    <tbody className="w-full">
                                        <tr className="w-full">
                                            {/* <td className="w-1/2">
                                                <button className="" onClick={handleBalanceDisplay}>
                                                    <div id="balance" className='mx-6 rounded-full border border-accent'>                                          
                                                        {buyerBalance >= 0 && (
                                                            <p className='text-lg text-center px-4 py-1'>             
                                                                {buyerBalance !== 0 && (
                                                                    "$" + buyerBalance / 100
                                                                )}
                                                                {buyerBalance === 0 && (
                                                                    "$0.00"
                                                                )}  
                                                            </p>
                                                        )}
                                                    </div>
                                                </button>
                                            </td> */}

                                            <td className="w-full">
                                                <div className="w-full">    
                                                    <div id="cta_button_register" className='bg-accent rounded-full relative'>
                                                        <button onClick={handleAccountMenu}>
                                                            <p className='text-lg font-medium text-white py-1 px-4'>Account</p>
                                                        </button>
                                                    </div>

                                                    {role !== "login" && ( isAccountMenuOpen && (
                                                        <div className="mt-2 z-40 absolute w-2/3">
                                                            <div className="float-left text-center border w-1/2 bg-white border-accent rounded-lg">
                                                                <div className=" text-base py-1 px-4">
                                                                    <a href="/dashboard/">
                                                                        <p className="">Dashboard</p>
                                                                    </a>
                                                                </div>

                                                                <hr className="" />

                                                                <div className=" text-base py-1 px-4">
                                                                    <a href="/seller/0">
                                                                        <p className="">My shop</p>
                                                                    </a>
                                                                </div>
                
                                                                <hr className="" />
                
                                                                <div className=" text-base py-1 px-4">
                                                                    <a href="/settings/">
                                                                        <p className="">Settings</p>
                                                                    </a>
                                                                </div>
                
                                                                <hr className="" />
                
                                                                <div className=" text-base py-1 px-4">
                                                                    <button onClick={handleLogOut}>
                                                                        <p className="text-red-400">Log out</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Header