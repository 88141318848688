import axios from 'axios'
import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { PRODUCTS_URL, PRODUCT_THUMBNAIL_UPLOADS_URL, SEARCH_URL } from '../../../../configs/api/api.config'
import scrollToTop from '../../../../hooks/scrollToTop'
import EditProduct from './EditProduct'

function Item({item, store_name, currentStore, changeFoundProducts, changeNoFoundProducts, changeError, changeSuccess}) {
  const newCookies = new Cookies()
  const [searchParams, setSearchParams] = useSearchParams();

  const { store } = useParams()

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function deleteProduct(){
    const user = getCookie()

    axios.delete(PRODUCTS_URL + "?store_id=" + currentStore.store_id + "&product_id=" + item.product_id, {
      headers: {
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          changeSuccess(response.data.message)
                  
          axios.get(SEARCH_URL + "from-store?keywords=" + searchParams.get("keywords"), {
            headers: {
              "x-access-token": user
            }
          })
            .then((response) => {
              if(response.data.success){
                changeFoundProducts(response.data.data)
                changeNoFoundProducts(false)
              } else if(response.data.message === "No products where found."){
                changeNoFoundProducts(true)
              }
            })
        } else {
          changeError(response.data.message)
          scrollToTop()
        }
      })
  }

  function handleEditProduct(){
    setIsEditPopupOpen(true)
    setSearchParams({page: "products", keywords: searchParams.get("keywords"), action: "edit"})
  }

  return (
    <div className='w-full border border-accent bg-accent/5 rounded-2xl my-2'>
      { isEditPopupOpen && ( <EditProduct store_id={currentStore.store_id} product={item} changeIsPopupOpen={setIsEditPopupOpen} /> )}

      <div className="flex flex-row m-3 h-auto">
        <img src={PRODUCT_THUMBNAIL_UPLOADS_URL + item.thumbnail} alt="" className='h-[100px] w-[100px] object-cover rounded' />
        
        <div className="ml-2 text-left w-full">
          <p className="text-lg">{item.title}</p>
          <p className="font-light">{item.description}</p>

          <div className="flex flex-row mt-4">
            <a href={"/s/" + encodeURI(store_name) + "?product=" + item.product_id}>
              <p className="mr-4">View</p>
            </a>
            <button onClick={handleEditProduct}>
              <p className="mr-4">Edit</p>
            </button>
            <button onClick={deleteProduct}>
              <p className="text-red-500">Delete</p>
            </button>
          </div>
        </div>

        <div className="float-right text-right mr-2">
          <table className="h-full">
            <tbody className="h-full">
              <tr className="h-full">
                <td className="align-middle">
                  <p className="align-middle inline-block">${(item.price / 100)}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Item