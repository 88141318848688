import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useSearchParams } from 'react-router-dom'
import { AUTHENTICATION_URL } from '../configs/api/api.config';
import Header from '../components/Header';

function Connect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newCookies = new Cookies()

  const [open, setOpen] = useState(false)
  const [user, setUser] = useState()

  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)

  function getCookie(){
    if(newCookies.get('user')){
        return newCookies.get('user')
    }
  }  
  
  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
          headers: {
          'x-access-token': user
          }
      })
          .then((response) => {
              if(response.data.success){
                  setUser(response.data.user_id)
              } else {
                  newCookies.remove("user", {path: "/"})
              }
          })
      }      
  }

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    console.log(searchParams.get("code"), user, loading)
    if(searchParams.get("code") && user){
      
      axios.get(AUTHENTICATION_URL + "discord?code=" + searchParams.get("code"), {
        headers: {
          "x-access-token": newCookies.get("user")
        },
      })
        .then((response) => {
          setLoading(false)
          if(response.data.success){
            setSuccess(true)
          } else {
            setSuccess(false)
          }
        })      
    }
  }, [user])


  if(loading){
    return (
      <div>
        Loading...
      </div>
    )
  } else {
    if(success){
      return (
        <div>
          <Header />
          
          <div className="w-full text-center pt-20 flex flex-col items-center">
            <div className="w-1/2 border-2 border-green-500 rounded-2xl flex flex-col items-center pt-20">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="bi bi-check-circle-fill text-green-500" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
              <div className="pt-10 pb-20">
                Success! Your discord account has been connected.
                <a href="/dashboard">
                  <p className="text-accent">Click here to return to your dashboard.</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Header />
          
          <div className="w-full text-center pt-20 flex flex-col items-center">
            <div className="w-1/2 border-2 border-red-500 rounded-2xl flex flex-col items-center pt-20">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="bi bi-x-circle-fill text-red-500" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
              <div className="pt-10 pb-20">
                Connecting your Discord failed. Please try again.
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Connect