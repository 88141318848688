import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { AUTHENTICATION_URL, PAYMENTS_URL, USERS_URL } from '../../../../configs/api/api.config'
import Cookies from 'universal-cookie'
import { WEBSITE_URL } from '../../../../configs/Website.config'
import scrollToTop from '../../../../hooks/scrollToTop'

function Connect({user, role, user_info, setError, setSuccess}) {
  const newCookies = new Cookies()
  
  const [words, setWords] = useState("")
  const [robloxId, setRobloxId] = useState()

  const [countryCode, setCountryCode] = useState()
  const [stripeLink, setStripeLink] = useState()
  const [stripeMessage, setStripeMessage] = useState(false)

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function handleRobloxAccountLinkInput(e){
    const input = e.target.value
    const isnum = /^\d+$/.test(input);

    if(isnum){
      setRobloxId(input)
    } else {
      const link_parts = input.split('/')
      
      for(let i = 0; i < link_parts.length; i++){
        if(link_parts[i] === 'users'){
          setRobloxId(link_parts[i + 1])
        }
      }
    }
  }

  function handleGenerateRoblox(){
    const user = getCookie()

    axios.get(AUTHENTICATION_URL + "verify-roblox/generate?roblox_id=" + robloxId, {
      headers: { 
        "x-access-token": user
      }
    })
      .then((response) => {
        console.log(response)
        if(response.data.success){
          setWords(response.data.data)
        } else {
          setError(response.data.message)
          scrollToTop()
        }
      })
  }

  function handleCheckRoblox(){
    const user = getCookie()

    axios.get(AUTHENTICATION_URL + "verify-roblox", {
      headers: { 
        "x-access-token": user
      }
    })
      .then((response) => {
        console.log(response.data)
        if(response.data.success){
          setSuccess(response.data.message)
          setWords("")
          setError("")
        } else {
          setError(response.data.message)
        }
        scrollToTop()
      })
  }

  function connectStripe(){    
    const user = getCookie()

    axios.get(PAYMENTS_URL + "onboard?country_code=" + countryCode, {
      headers: { 
        "x-access-token": user
      }
    })
      .then((response) => {
        if(response.data.success){
          setStripeLink(response.data.link)
          setStripeMessage(true)
          window.open(response.data.link, '_blank', 'noreferrer');
        } else {
          setError(response.data.message)
          scrollToTop()
        }
      })   
  }

  function handleCountryInput(e){
    setCountryCode(e.target.value)
  }

  return (
    <div>
      <div className="pt-4">
        <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6">Connect</p>

        <div className="w-full md:w-1/2 mx-auto">           
          <div className="text-left mt-8">
            <p className="mb-1 font-medium text-base md:text-lg lg:text-xl ml-6">Discord</p>
          </div>

          <div className="mt-5">
            <a href='https://discord.com/api/oauth2/authorize?client_id=1010859384278097920&redirect_uri=https%3A%2F%2Falpha.roshop.dev%2Fconnect%2Fdiscord&response_type=code&scope=identify%20guilds' rel="noreferrer" target="_blank">
              <div className="bg-accent text-white text-center font-semibold rounded-full w-full px-6 py-2">
                Connect
              </div>
            </a>
          </div>

          <div className="text-left mt-8">
            <p className="mb-1 font-medium text-base md:text-lg lg:text-xl ml-6">Roblox</p>
          </div>

          <div className="text-left mt-5">              
            {!words && (
              <div className="">
                <p className="ml-6 mb-1">Roblox account link</p>
                <table className="w-full">
                  <tbody className="w-full">
                    <tr className="w-full">
                      <td className="w-full align-middle">
                        <input onChange={handleRobloxAccountLinkInput} type="text" name="" id='' className="border-accent border rounded-full px-6 w-full py-2" />
                      </td>
                      <td className=" align-middle">
                        <button onClick={handleGenerateRoblox} className="bg-accent text-white font-semibold rounded-full px-6 py-2">
                          Connect
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            
            {words && (
              <div className="w-full">
                <p className="ml-6 mb-1">Place the following words in your Roblox status: <span className="font-bold">{words}</span></p>
                
                <button onClick={handleCheckRoblox} className="bg-accent text-white w-full font-semibold rounded-full px-6 py-2">
                  Check account
                </button>
              </div>
            )}
          </div>

          {/* 
          <div className="text-left mt-12">
            <p className="mb-1 font-medium text-base md:text-lg lg:text-xl ml-6">Discord account</p>
          </div>
          <button className="bg-accent text-white font-semibold rounded-full w-full mt-2 px-6 py-2">
            Connect discord account
          </button>
          */}
          <div className="text-left mt-12">
            <p className="mb-1 font-medium text-base md:text-lg lg:text-xl ml-6">Stripe account</p>
          </div>
          <table className="w-full">
            <tbody className="w-full">
              {!stripeLink && (
                <tr className="w-full">
                  <td className="w-full align-middle">
                    {/* <input onChange={handleRobloxAccountLinkInput} type="country" name="" id='' className="" /> */}
                    <select onChange={handleCountryInput} id="countrySelector" name="country" className="border-accent border rounded-full px-6 w-full py-2">
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="BE">Belgium</option>
                      <option value="BG">Bulgaria</option>
                      <option value="CA">Canada</option>
                      <option value="HR">Croatia</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="EE">Estonia</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IE">Ireland</option>
                      <option value="IT">Italy</option>
                      <option value="JP">Japan</option>
                      <option value="LV">Latvia</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MT">Malta</option>
                      <option value="MX">Mexico</option>
                      <option value="NL">Netherlands</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NO">Norway</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="RO">Romania</option>
                      <option value="SG">Singapore</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="ES">Spain</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="TH">Thailand</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                    </select>
                  </td>
                  <td className=" align-middle">
                    <button onClick={connectStripe} className="bg-accent text-white font-semibold rounded-full px-6 py-2">
                      Connect
                    </button>
                  </td>
                </tr>
              )}
              {stripeLink && (
                <tr className="w-full">
                  <td className="w-full align-middle">
                    <div className="border-accent border rounded-full px-6 w-full py-2">
                      <p className="">{stripeLink}</p>
                    </div>
                    {stripeMessage && ( <p className="text-accent text-sm mt-1 pl-6">Complete your Stripe account in the opened link.</p> )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div> 
      </div>
    </div>
  )
}

export default Connect