import React from 'react'

import { steps_heading, steps_subheading, steps } from "../../configs/home/Steps.config"

function Steps() {
  return (
    <div className="container mx-auto mt-40">
      <div className="w-2/3 mx-auto">
        <p className="text-2xl font-extrabold text-center">{steps_heading}</p>
        <p className="text-center w-full md:w-3/5 py-3 mx-auto">{steps_subheading}</p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-4">
          {steps.map((step, key) => {
            return (
              <div key={key} className="bg-accent/20 rounded-2xl min-h-[150px]">
                <div className="w-1/6 mx-auto my-2 text-white font-bold bg-accent/25 rounded-full aspect-square">
                  <table className='w-full h-full'>
                    <tbody className='w-full h-full'>
                      <tr className='w-full h-full'>
                        <td className="align-middle text-center w-full h-full">
                          <p className="">{step.step_number}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <p className="text-center w-11/12 mx-auto p-2">{step.step_description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Steps