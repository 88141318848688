import React, { useEffect, useState } from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie'


import Header from '../components/Header'
import Footer from '../components/Footer'
import { AUTHENTICATION_URL, USERS_URL } from '../configs/api/api.config'
import Error from '../components/states/Error'
import Success from '../components/states/Success'
import Warning from '../components/states/Warning'
import scrollToTop from '../hooks/scrollToTop';

function Login() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [loginOrRegister, setLoginOrRegister] = useState(false)

  // States
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [warning, setWarning] = useState("")
  const [loading, setLoading] = useState(false)
  
  const handleUsername = (e) => {
    setUsername(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(sha256(e.target.value))
  }

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
            setError("")
            navigate("/dashboard")

            setLoginOrRegister(true)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  const handleLogin = () => {
    axios.post(AUTHENTICATION_URL + "login", {
      username: username,
      password: password
    })
      .then((response) => {
        console.log(response)
        if(response.data.success){
          newCookies.set("user", response.data.token, { path: '/' })
          getUser()
        } else {
          setError(response.data.message)
          scrollToTop()
        }
      })
  }

  return (
    <div>
        <Header user={user} role={role} loginOrRegister={loginOrRegister} />

        {error && (<Error message={error} changeMessage={setError} />)}
        {warning && (<Warning message={warning} changeMessage={setWarning} />)}
        {success && (<Success message={success} changeMessage={setSuccess} />)}


        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Login</p>

            <div className="md:w-1/2 mx-auto mt-10">
              <div className="text-left">
                <p className="ml-6 mb-1">Username</p>
                <input onChange={handleUsername} type="text" name="" id='username' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
              </div>
              
              <div className="text-left mt-5">
                <div className="flex flex-row">
                  <p className="ml-6 mb-1 w-full">Password</p>
                  <a href="/forgot-password">
                    <p className="text-left float-right mr-2 mb-1 text-accent underline underline-offset-2">Forgot&nbsp;password?</p>
                  </a>
                </div>
                <input onChange={handlePassword} type="password" name="" id='password' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                
              </div>
              
              <button onClick={handleLogin} id='login' className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                <p className="w-full text-white font-bold">Login</p>
              </button>

              <a href="/register">
                <p className="text-left pt-3 ml-2 text-accent underline underline-offset-2">Or create an account.</p>
              </a>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Login