import React, {Suspense, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import App from './pages/App';
import Features from './pages/Features';
import Resources from './pages/Resources';
import Pricing from './pages/Pricing';
import Explore from './pages/Explore';
import Login from './pages/Login';
import Register from './pages/Register';
import Search from './pages/Search';
import ForgotPassword from './pages/ForgotPassword';
import Checkout from './pages/Checkout';
import CheckoutConfirm from './pages/CheckoutConfirm';

import Dashboard from './pages/Dashboard';
import Connect from './pages/ConnectDiscord';
import SellerDashboard from './pages/SellerDashboard';
import Settings from './pages/Settings';

import ComingSoon from './pages/ComingSoon';
import NotFound from './pages/NotFound';
import Webshop from './pages/Webshop';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGmZcLWd9XxHg7W7Oa0QERDOkrECVb-wk",
  authDomain: "roshop-landing.firebaseapp.com",
  projectId: "roshop-landing",
  storageBucket: "roshop-landing.appspot.com",
  messagingSenderId: "206949801618",
  appId: "1:206949801618:web:90e6226d724497785f904b",
  measurementId: "G-6HH18NNZHD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
      <Routes>
          {/* Public Pages */}
          <Route path="/" element={<App />} />
          <Route path="/features" element={<Features />} />
          <Route path="/resources" element={<Resources />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/explore" element={<Explore />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/search" element={<Search />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/complete" element={<CheckoutConfirm />} />

          {/* Dashboards */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/connect/discord" element={<Connect />} />
          <Route path="/seller/:store" element={<SellerDashboard />} />
          <Route path="/settings" element={<Settings />} />

          {/* Webshop */}
          <Route path="/s/:store_name/" element={<Webshop />} />
          <Route path="/s/:store_name/:page" element={<Webshop />} />

          {/* Others */}
          <Route path="*" element={<NotFound />} />
          <Route path="**/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
        </Suspense>
  </React.StrictMode>
);