import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import ComingSoonSVG from "../assets/svg/coming_soon.svg"
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'



function ComingSoon() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          console.log(response)
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
        <Header user={user} role={role} />


        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
          <img src={ComingSoonSVG} className="pb-8 max-h-[500px] mx-auto" alt="under construction" />

          <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">COMING SOON!</p>
          <p className="text-lg mt-4 w-5/6 md:w-2/3 lg:w-2/5 mx-auto">We're currently working on this page, come back later and/or join <span className="text-accent"><a href="https://discord.gg/YzJzSfDjzf">our discord server</a></span> to get updates</p>
        </div>

        <Footer />
    </div>
  )
}

export default ComingSoon