const api_url = "https://api.roshop.dev/"
// const api_url = "http://localhost:4000/"
// const api_url = "https://193.46.198.230/"
module.exports = {
    API_URL: api_url,
    CORS_PROXY: "https://cors-anywhere.herokuapp.com/",

    // Routes
    ADMIN_URL: api_url + "admin/",
    AUTHENTICATION_URL: api_url + "auth/",
    PAYMENTS_URL: api_url + "payments/",
    PRODUCTS_URL: api_url + "products/",
    SEARCH_URL: api_url + "search/",
    STORES_URL: api_url + "stores/",
    USERS_URL: api_url + "users/",
    PRODUCT_THUMBNAIL_UPLOADS_URL: api_url + "uploads/products/thumbnails/",

}