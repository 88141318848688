import React, { useEffect, useState } from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import { useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";
import Cookies from 'universal-cookie'

import { AUTHENTICATION_URL } from '../../configs/api/api.config'
import Error from '../states/ErrorOnTop'
import Success from '../states/SuccessOnTop'
import Warning from '../states/WarningOnTop'

function Register({ theme_color, changeUser }) {
  const newCookies = new Cookies()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [tos, setTos] = useState(false)
  const [loginOrRegister, setLoginOrRegister] = useState(false)

  // States
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [warning, setWarning] = useState("")
  const [loading, setLoading] = useState(false)
  
  const styles = {
    theme_text: {
        color: theme_color
    },
    theme_bg: {
        backgroundColor: theme_color
    },
    theme_border: {
        borderColor: theme_color
    },
    theme_accent: {
        accentColor: theme_color
    }
  }

  const handleUsername = (e) => {
    setUsername(e.target.value)
  }
  
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(sha256(e.target.value))
  }

  const handleTOS = () => {
    if(tos){ 
      setTos(false)
    } else {
      setTos(true)
    }
  }
  
  const checkInput = () => {
    if(username.length < 3){
      return 1
    }

    if(email.length === 0 || !validator.isEmail(email)){
      return 2
    }

    if(password.length === 0){
      return 3
    }

    if(!tos){
      return 4
    }

    return true
  }

  const handleRegister = () => {
    const validateInputs = checkInput()

    if(validateInputs === true){
      axios.post(AUTHENTICATION_URL + "register", {
        username: username,
        email: email,
        password: password,
        referer: newCookies.get("ref")
      })
        .then((response) => {
          if(response.data.success){
            newCookies.set("user", response.data.token, { path: '/' })
            changeUser(true)
            getUser()
            closePopup()

            setError("")
          } else {
            setError(response.data.message)
          }
        })
    } else {
      if(validateInputs === 1){        
        setError("An username longer then 3 and shorter then 20 characters should be entered.")
      } else if(validateInputs === 2){
        setError("A valid email should be entered.")
      } else if(validateInputs === 3){
        setError("A password should be entered.")
      } else {
        setError("You should agree to our Terms of Service to start using our service.")
      }
    }
  }

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")


  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
            closePopup()

            setWarning("You are already logged in, <a href='/dashboard'><span className='underline underline-offset-2'>click to go to your dashboard</span></a>.")
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  function closePopup(){
    if(searchParams.get('product')){
      setSearchParams({'product': searchParams.get('product')})
    } else {
      setSearchParams({})
    }
  }

  function openLoginPopup(){
    if(searchParams.get('product')){
      setSearchParams({'product': searchParams.get('product'), 'action': 'login'})
    } else {
      setSearchParams({'action': 'login'})
    }
  }


  return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
      <div className="w-full md:w-3/5 xl:w-2/5 h-3/4 md:h-2/3 mt-32 rounded-xl bg-white m-auto z-50">

        <div className="pt-3">
          {error && (<Error message={error} changeMessage={setError} />)}
          {warning && (<Warning message={warning} changeMessage={setWarning} />)}
          {success && (<Success message={success} changeMessage={setSuccess} />)}
        </div>

        <div className="m-auto mt-4 text-center container">
            <div className="w-full">
              <table className="w-full">
                <tbody className="w-full">
                  <tr className="w-full">
                    <td className="w-full">
                      <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Register</p>
                    </td>
                    <td className="">
                      <button onClick={closePopup} className="fixed -mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-2/3 mx-auto mt-10">
              <div className="text-left">
                <p className="ml-6 mb-1">Username</p>
                <input onChange={handleUsername} type="text" name="" id="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} required />
              </div>

              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Email</p>
                <input onChange={handleEmail} type="email" name="" id="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} required />
              </div>
              
              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Password</p>
                <input onChange={handlePassword} type="password" name="" id="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} required />
              </div>
              
              <div className="text-left mt-8">
                <div className="flex flex-row">
                  <table>
                    <tbody>
                      <tr>
                        <td className='align-middle'>
                          <input type="checkbox" onChange={handleTOS} name="" className='ml-6 mb-1 accent-accent scale-150' style={styles.theme_accent} required />
                        </td>
                        <td className='align-middle'>
                          <p className="text-base ml-3">I agree with the <span className='text-accent underline underline-offset-2' style={styles.theme_text}><a href="/terms-of-service">Terms of Service</a></span>.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <button onClick={handleRegister} className="rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5" style={styles.theme_bg}>
                <p className="w-full text-white font-bold">Let's start today</p>
              </button>

              <button onClick={openLoginPopup} className='w-full'>
                <p className="text-left pt-3 ml-2 text-accent underline underline-offset-2" style={styles.theme_text}>Already have an account?</p>
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Register