import React from 'react'
import Dashboard from '../components/Dashboards/Buyer/Dashboard'

function DashboardPage() {
  return (
    <div>
        <Dashboard />
    </div>
  )
}

export default DashboardPage