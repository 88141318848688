import React from 'react'
import { useState } from 'react'

function SideMenu({changePage, isPartner}) {
  const [isOpen, setIsOpen] = useState(false)

  function handleMenuMobile(){
    if(isOpen){
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div className="">
      <div className='hidden md:inline border-r md:w-1/4 lg:w-1/5 bg-accent/10 absolute top-14 h-screen'>
          <div className="py-4">
              <button className='w-full' onClick={() => { changePage("products") }}>
                <p className="text-center">Purchased Products</p>
              </button>
          </div>
          <hr />
          <div className="py-4">
              <button className='w-full' onClick={() => { changePage("transactions") }}>
                <p className="text-center">Transactions</p>
              </button>
          </div>
          <hr />
          {isPartner && (
          <div className="py-4">
              <button className='w-full' onClick={() => { changePage("partners") }}>
                <p className="text-center">Partners</p>
              </button>
          </div>
          )}
          {isPartner && (
            <hr />
          )}
          <div className="py-4">
              <button className='w-full' onClick={() => { changePage("connect") }}>
                <p className="text-center">Connect</p>
              </button>
          </div>
          <hr />
          <div className="py-4">
              <a href="/settings">
                <p className="text-center">Settings</p>
              </a>
          </div>
          <hr />
      </div>

      <div className="md:hidden">
        {!isOpen && (
          <div className="">
            <button className='mt-6 pl-2 pr-3 py-2 bg-accent/10 rounded-r-xl' onClick={handleMenuMobile}>
              <p className="font-extrabold">&gt;</p>
            </button>
          </div>
        )}

        {isOpen && (
          <div className="">
            <button className="mt-6 pl-2 pr-3 py-2" onClick={handleMenuMobile}>
              <p className="font-extrabold">{"<"}</p>
            </button>
          </div>
        )}

        {isOpen && (
          <div className="h-screen"> 
            <div className="py-4">
              <button className='w-full' onClick={() => { changePage("products"); setIsOpen(false) }}>
                <p className="text-center">Products</p>
              </button>
            </div>

            <hr />

            <div className="py-4">
              <button className='w-full' onClick={() => { changePage("transactions"); setIsOpen(false) }}>
                <p className="text-center">Transactions</p>
              </button>
            </div>

            <hr />

            {isPartner && (
              <div className="py-4">
                <button className='w-full' onClick={() => { changePage("partners"); setIsOpen(false) }}>
                  <p className="text-center">Partners</p>
                </button>
              </div>
            )}

            {isPartner && (
              <hr />
            )}

            <div className="py-4">
              <button className='w-full' onClick={() => { changePage("connect"); setIsOpen(false) }}>
                <p className="text-center">Connect</p>
              </button>
            </div>

            <hr />

            <div className="py-4">
              <a href="/settings">
                <p className="text-center">Settings</p>
              </a>
            </div>
            
            <hr />
          </div>
        )}
      </div>
    </div>
  )
}

export default SideMenu