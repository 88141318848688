import React, { useEffect, useState } from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie'

import Header from '../Header'
import Footer from '../Footer'
import { AUTHENTICATION_URL, USERS_URL } from '../../configs/api/api.config'
import Error from '../states/ErrorOnTop'
import Success from '../states/SuccessOnTop'
import Warning from '../states/WarningOnTop'

function Login({ theme_color, changeUser }) {
  const newCookies = new Cookies()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [loginOrRegister, setLoginOrRegister] = useState(false)

  // States
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [warning, setWarning] = useState("")
  const [loading, setLoading] = useState(false)
  
  const styles = {
    theme_text: {
        color: theme_color
    },
    theme_bg: {
        backgroundColor: theme_color
    },
    theme_border: {
        borderColor: theme_color
    }
  }

  const handleUsername = (e) => {
    setUsername(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(sha256(e.target.value))
  }

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
            setError("")
            setWarning("You are already logged in, <a href='/dashboard'><span className='underline underline-offset-2'>click to go to your dashboard</span></a>.")

            setLoginOrRegister(true)
            closePopup()
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  const handleLogin = () => {
    axios.post(AUTHENTICATION_URL + "login", {
      username: username,
      password: password
    })
      .then((response) => {
        if(response.data.success){
          newCookies.set("user", response.data.token, { path: '/' })
          changeUser(true)
          getUser()
          closePopup()
        } else {
          setError(response.data.message)
        }
      })
  }

  function closePopup(){
    if(searchParams.get('product')){
      setSearchParams({'product': searchParams.get('product')})
    } else {
      setSearchParams({})
    }
  }

  function openRegisterPopup(){
    if(searchParams.get('product')){
      setSearchParams({'product': searchParams.get('product'), 'action': 'register'})
    } else {
      setSearchParams({'action': 'register'})
    }
  }

  return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-black/90 z-40'>
      <div className="w-full md:w-3/5 xl:w-2/5 h-2/3 md:h-1/2 mt-32 rounded-xl bg-white m-auto z-50">
            <div className="pt-3">
              {error && (<Error message={error} changeMessage={setError} />)}
              {warning && (<Warning message={warning} changeMessage={setWarning} />)}
              {success && (<Success message={success} changeMessage={setSuccess} />)}
            </div>
        <div className="m-auto text-center container pt-4">
            <div className="w-full">
              <table className="w-full">
                <tbody className="w-full">
                  <tr className="w-full">
                    <td className="w-full">
                      <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Login</p>
                    </td>
                    <td className="">
                      <button onClick={closePopup} className="fixed -mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-2/3 mx-auto mt-10">
              <div className="text-left">
                <p className="ml-6 mb-1">Username</p>
                <input onChange={handleUsername} type="text" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} />
              </div>
              
              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Password</p>
                <input onChange={handlePassword} type="password" name="" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" style={styles.theme_border} />
              </div>
              
              <button onClick={handleLogin} className="rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5" style={styles.theme_bg}>
                <p className="w-full text-white font-bold">Login</p>
              </button>

              <button onClick={openRegisterPopup} className='w-full'>
                <p className="text-left pt-3 ml-2 text-accent underline underline-offset-2" style={styles.theme_text}>Or create an account.</p>
              </button>

            </div>
        </div>
      </div>
    </div>
  )
}

export default Login