import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import RoShop from "../../assets/images/RoShop_long_Coco_Gothic_Fat.png"
import BalancePopup from "../popups/Balance"
import { USERS_URL } from '../../configs/api/api.config'


function Header({ user, role, store_name, amount_to_remove_to_buyer_balance, theme_color }) {
    const newCookies = new Cookies()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const [isNavOpenMobile, setIsNavOpenMobile] = useState(false)
    const [keywords, setKeywords] = useState("")

    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false)
    const [isBalancePopupOpen, setIsBalancePopupOpen] = useState(false)
    const [buyerBalance, setBuyerBalance] = useState()
    const [sellerBalance, setSellerBalance] = useState()

    const [userInfo, setUserInfo] = useState(false)

    const styles = {
        theme_text: {
            color: theme_color
        },
        theme_bg: {
            backgroundColor: theme_color
        },
        theme_border: {
            borderColor: theme_color
        }
    }

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
    }  

    useEffect(() => {
        const userCookie = getCookie()

        axios.get(USERS_URL, {
            headers: {
              'x-access-token': userCookie
            }
        })
            .then((response) => {
              if(response.data.success){
                setUserInfo(response.data.data[0]) 
                setBuyerBalance(response.data.data[0].buyer_balance)
                setSellerBalance(response.data.data[0].seller_balance)
              } else {
                console.log("User info could not be retrieved.")
              }
            })
    }, [user])

    useEffect(() => {
        setBuyerBalance(buyerBalance - amount_to_remove_to_buyer_balance)
    }, [amount_to_remove_to_buyer_balance])

    function handleMenu(){
        if(isNavOpenMobile){
            setIsNavOpenMobile(false)
        } else {
            setIsNavOpenMobile(true)
        }
    }

    function handleSearchInput(e){
        setKeywords(e.target.value)
    }

    function handleSearchEnter(e){
        if(e.key === "Enter"){
            navigate("/search?keywords=" + keywords + "&sort-by=popular&page=1")
        }
    }

    function handleSearchClick(){
        navigate("/search?keywords=" + keywords + "&sort-by=popular&page=1")
    }

    function handleAccountMenu(){
        if(isAccountMenuOpen){
            setIsAccountMenuOpen(false)
        } else {
            setIsAccountMenuOpen(true)
        }
    }

    function handleLogOut(){
        newCookies.remove("user", {path: "/"})
        window.location.reload(false);
    }

    function getCookie(){
        if(newCookies.get('user')){
          return newCookies.get('user')
        }
      }  
    
    function handleBalanceDisplay(){
        setIsBalancePopupOpen(true)
    }


  return (
    <div className="w-full">
        <div className='bg-white w-full fixed z-30'>
            {isBalancePopupOpen && (
                <BalancePopup changeDisplay={setIsBalancePopupOpen} changeBuyerBalance={setBuyerBalance} changeSellerBalance={setSellerBalance} />
            )}
            <div className="container bg-white flex flex-row justify-between mx-auto my-2">
                <div className="flex flex-row">
                    <table>
                        <tbody>
                            <tr>
                                <td className="align-middle">
                                    <div id="header_logo">
                                        <a href={"/s/" + store_name + "/"}>
                                            <p className="text-2xl md:text-3xl font-black" style={styles.theme_text}>{store_name}</p>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
        
                    <div id="header_navigation" className="lg:flex lg:flex-row hidden ml-6">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="align-middle">
                                        <div id="navigation_item" className='mx-2'>
                                            <a href={"/s/" + store_name + "/"}>
                                                <p className='text-lg hover:border-b-2 border-accent' style={styles.theme_border}>Products</p>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="align-middle">
                                        <div id="navigation_item" className='mx-2'>
                                            <a href={"/s/" + store_name + "/about"}>
                                                <p className='text-lg hover:border-b-2 border-accent' style={styles.theme_border}>About</p>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div id="header_search" className="hidden lg:flex">
                        <div className=" border-accent border rounded-full flex flex-row px-6 mr-10" style={styles.theme_border}>
                            <table className="">
                                <tbody className="">
                                    <tr className="">
                                        <td className="align-middle">
                                            <input type="text" placeholder='search on RoShop...' onChange={handleSearchInput} onKeyDown={handleSearchEnter} name="" className="focus:outline-0 placeholder:text-sm " />
                                        </td>
                                        <td className="align-middle">
                                            <button className="" onClick={handleSearchClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={theme_color} className="bi bi-search text-accent" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="header_call_to_action" className='lg:flex lg:flex-row hidden'>
                        <table>
                            <tbody>
                                {role === "login" && (
                                    <tr>
                                        <td className="align-middle">
                                            <div id="cta_button_login" className='mx-4'>
                                                <a href={searchParams.get('product') ? "?product="+ searchParams.get('product') +"&action=login" : "?action=login"}>
                                                    <p className='text-lg'>Login</p>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div id="cta_button_register" className='bg-accent rounded-full' style={styles.theme_bg}>
                                                <a href={searchParams.get('product') ? "?product="+ searchParams.get('product') +"&action=register" : "?action=register"}>
                                                    <p className='text-lg font-medium text-white py-1 px-4'>Register</p>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {role !== "login" && (
                                    <tr>
                                        <td className="align-middle">
                                            <div className="">
    
                                                <div id="cta_button_register" className='bg-accent rounded-full relative' style={styles.theme_bg}>
                                                    <button onClick={handleAccountMenu}>
                                                        <p className='text-lg font-medium text-white py-1 px-4'>Account</p>
                                                    </button>
                                                </div>
    
                                                {isAccountMenuOpen && (
                                                    <div className="mt-2 z-40 absolute">
                                                        <div className="float-left text-center border bg-white border-accent rounded-lg" style={styles.theme_border}>
                                                            <div className=" text-base py-1 px-4">
                                                                <a href="/dashboard">
                                                                    <p className="">Dashboard</p>
                                                                </a>
                                                            </div>
    
                                                            <hr className="" />

                                                            <div className=" text-base py-1 px-4">
                                                                <a href="/seller/0">
                                                                    <p className="">My shop</p>
                                                                </a>
                                                            </div>

                                                            <hr className="" />
    
    
                                                            <div className=" text-base py-1 px-4">
                                                                <a href="/settings">
                                                                    <p className="">Settings</p>
                                                                </a>
                                                            </div>
    
                                                            <hr className="" />
    
                                                            <div className=" text-base py-1 px-4">
                                                                <button onClick={handleLogOut}>
                                                                    <p className="text-red-400">Log out</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="align-middle">                                           
                                            <a href="/checkout">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart3 text-accent ml-4" viewBox="0 0 16 16" style={styles.theme_text}>
                                                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="phone_header_navigation" className="lg:hidden">
                    <div id="menu_opener" className="h-full">
                        <table className="h-full">
                            <tbody className="h-full">
                                <tr className="h-full">
                                    <td className="h-full">
                                        <button className="align-middle" onClick={handleMenu}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {isNavOpenMobile && (
                        <div id="phone_navigation" className='w-full h-full fixed left-0 bg-white'>
                            <div className="container mx-auto mt-5">
                                <div className="text-left">
                                    <a href={"/s/" + store_name + "/"}>
                                        <p className="text-lg font-medium px-6 py-4" style={styles.theme_border}>Products</p>
                                    </a>
                                </div>

                                <hr />

                                <div className="text-left">
                                    <a href={"/s/" + store_name + "/about"}>
                                        <p className="text-lg font-medium px-6 py-4">About</p>
                                    </a>
                                </div>

                                <hr />
                                

                                <div id="header_search" className="px-6 py-4">
                                    <div className=" border-accent border rounded-full flex flex-row px-6 mr-10 py-2" style={styles.theme_border}>
                                        <table className="w-full">
                                            <tbody className="w-full">
                                                <tr className="w-full">
                                                    <td className="align-middle w-full">
                                                        <input type="text" onChange={handleSearchInput} onKeyDown={handleSearchEnter} name="" className="focus:outline-0 w-full" />
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="float-right" onClick={handleSearchClick}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={theme_color} className="bi bi-search text-accent" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {role !== "login" && (
                                    <div className="flex flex-row text-center mx-6 my-4">
                                        <table className="w-full">
                                            <tbody className="w-full">
                                                <tr className="w-full">
                                                    <td className="w-1/2">
                                                        <div className="w-full pr-6">    
                                                            <div id="cta_button_register" className='bg-accent rounded-full relative' style={styles.theme_bg}>
                                                                <button onClick={handleAccountMenu}>
                                                                    <p className='text-lg font-medium text-white py-1 px-4'>Account</p>
                                                                </button>
                                                            </div>

                                                            {isAccountMenuOpen && (
                                                                <div className="mt-2 z-40 absolute w-2/3">
                                                                    <div className="float-left text-center border w-1/2 bg-white border-accent rounded-lg" style={styles.theme_border}>
                                                                        <div className=" text-base py-1 px-4">
                                                                            <a href="/dashboard">
                                                                                <p className="">Dashboard</p>
                                                                            </a>
                                                                        </div>
                                                                        
                                                                        <hr className="" />

                                                                        <div className=" text-base py-1 px-4">
                                                                            <a href="/seller/0">
                                                                                <p className="">My shop</p>
                                                                            </a>
                                                                        </div>
                        
                                                                        <hr className="" />
                        
                                                                        <div className=" text-base py-1 px-4">
                                                                            <a href="/settings">
                                                                                <p className="">Settings</p>
                                                                            </a>
                                                                        </div>
                        
                                                                        <hr className="" />
                        
                                                                        <div className=" text-base py-1 px-4">
                                                                            <button onClick={handleLogOut}>
                                                                                <p className="text-red-400">Log out</p>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>                                                    
                                                    <td className="align-middle">                                           
                                                        <a href="/checkout">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart3 text-accent ml-4" viewBox="0 0 16 16" style={styles.theme_text}>
                                                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                            </svg>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                
                                {role === "login" && (
                                    <table className='w-full'>
                                        <tbody className='w-full'>
                                            <tr className='w-full'>
                                                <td className="align-middle w-1/2">
                                                    <div id="cta_button_login" className='mx-2 text-center pl-6'>
                                                        <a href={searchParams.get('product') ? "?product="+ searchParams.get('product') +"&action=login" : "?action=login"}>
                                                            <p className='text-lg'>Login</p>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="align-middle w-1/2">
                                                    <div id="cta_button_register" className='bg-accent rounded-full text-center mr-12' style={styles.theme_bg}>
                                                        <a href={searchParams.get('product') ? "?product="+ searchParams.get('product') +"&action=register" : "?action=register"}>
                                                            <p className='text-lg font-medium text-white py-1 px-4'>Start&nbsp;Selling</p>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header