module.exports = {
    heading: "Resources",
    sub_heading: "Find an answer to all of the frequent asked questions about our marketplace.",
    resource_items: [
        {
            question: "How to join the testing program?",
            answer: "You can create a ticket in the discord server and ask to join the program.",
        },
        {
            question: "When will the website be public?",
            answer: "As fast as possible after the testing phase.",
        },
        {
            question: "I've registered what should I do now?",
            answer: "Create a discord ticket with the username that you've entered, we will accept the account to the website.",
        },
        {
            question: "Where can I find information about the testing program?",
            answer: "To access the program's information, you should be accepted to the program first after this you'll find the information in the discord server.",
        },
    ]
}