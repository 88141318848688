import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { USERS_URL } from '../../../../configs/api/api.config'
import Cookies from 'universal-cookie'
import Item from './ProductItem'

function Products({user, role, user_info, setError}) {
  const newCookies = new Cookies()

  const [ownedAssets, setOwnedAssets] = useState([])
  const [message, setMessage] = useState("")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  useEffect(() => {
    const user_cookie = getCookie()

    axios.get(USERS_URL + "bought-assets", {
      headers: {
        'x-access-token': user_cookie
      }
    })
      .then((response) => {
        if(response.data.success){
          setOwnedAssets(response.data.data)
        } else {
          setMessage(response.data.message)
          console.log(response.data.message)
        }
      })
  }, [])

  return (
    <div>
      <div className="mt-20">
        <p className="font-bold text-lg md:text-xl lg:text-2xl text-center mx-auto mb-6 pt-6 md:mt-0">Owned Products</p>

        {message && (
          <div className="w-full mt-4">
            <p className="text-center text-xl">{message}</p>
          </div>
        )}

        {ownedAssets && ownedAssets.map((item, key) => {
          return <Item item={item} key={key} />
        })}

      </div>
    </div>
  )
}

export default Products