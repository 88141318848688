import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import validator from "validator";
import Cookies from 'universal-cookie'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Error from '../components/states/Error'
import Warning from '../components/states/Warning'
import Success from '../components/states/Success'
import { AUTHENTICATION_URL, USERS_URL } from '../configs/api/api.config'
import sha256 from 'sha256';
import scrollToTop from '../hooks/scrollToTop';

function ForgotPassword() {
    const newCookies = new Cookies()
    const navigate = useNavigate()
  
    const [searchParams, setSearchParams] = useSearchParams();
  
    const [user, setUser] = useState(0)
    const [role, setRole] = useState("login")

    const [input, setInput] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordIsStrong, setPasswordIsStrong] = useState("")
    const [passwordIsStrongShow, setPasswordIsStrongShow] = useState(false)
  
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [warning, setWarning] = useState("")

    function getCookie(){
      if(newCookies.get('user')){
        return newCookies.get('user')
      }
    }  
  
    function getUser(){
      const user = getCookie()
  
      if(user){
        axios.get(AUTHENTICATION_URL, {
          headers: {
            'x-access-token': user
          }
        })
          .then((response) => {
            if(response.data.success){
              setUser(response.data.user_id)
              setRole(response.data.role)
            } 
          })
      }      
    }
  
    useEffect(() => {
      getUser()
    }, [])

    function handleInput(e){
      setInput(e.target.value)
    } 
    
    function handlePassword(e){
      setPassword(e.target.value)

      if(!validator.isStrongPassword(e.target.value, {minSymbols: 0})){
        if(e.target.value.length < 8){
          setError("The password should be minimum 8 characters long.")
          setPasswordIsStrong("The password should be minimum 8 characters long.")
        } else if(e.target.value.toUpperCase() === e.target.value){
          setError("The password should be contain a minimum of 1 lowercase character.")
          setPasswordIsStrong("The password should be contain a minimum of 1 lowercase character.")
        } else if(e.target.value.toLowerCase() === e.target.value){
          setError("The password should be contain a minimum of 1 uppercase character.")
          setPasswordIsStrong("The password should be contain a minimum of 1 uppercase character.")
        } else {
          setError("The password should be contain a minimum of 1 number.")
          setPasswordIsStrong("The password should be contain a minimum of 1 number.")
        }
      } else {
        setError("")
        setPasswordIsStrong("")
      }
    } 
    
    function handlePasswordConfirm(e){
      setPasswordConfirm(e.target.value)
    } 

    function handleSubmitReset(){
      if(password === passwordConfirm && passwordIsStrong === ""){
        axios.get(USERS_URL + 'forgot-password/verify?token=' + searchParams.get("token") + "&newPassword=" + sha256(password))
          .then((response) => {
            if(response.data.success){
              setSuccess(response.data.message)
              scrollToTop()
            } else {
              setError(response.data.message)
              scrollToTop()
            }
          })
      }
    }

    function handleSubmit(){
      if(validator.isEmail(input)){
        axios.get(USERS_URL + 'forgot-password?email=' + input)
          .then((response) => {
            if(response.data.success){
              setSuccess(response.data.message)
              scrollToTop()
            } else {
              setError(response.data.message)
              scrollToTop()
            }
          })
      } else {
        axios.get(USERS_URL + 'forgot-password?username=' + input)
          .then((response) => {
            if(response.data.success){
              setSuccess(response.data.message)
              scrollToTop()
            } else {
              setError(response.data.message)
              scrollToTop()
            }
          })
      }
    }

  if(!searchParams.get("token")){
    return (
      <div className=''>
          <Header user={user} role={role} />

          {error && (<Error message={error} changeMessage={setError} />)}
          {warning && (<Warning message={warning} changeMessage={setWarning} />)}
          {success && (<Success message={success} changeMessage={setSuccess} />)}


          <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
              <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Forgot password</p>

              <div className="md:w-1/2 mx-auto mt-10">
                <div className="text-left">
                  <p className="ml-6 mb-1">Email or username</p>
                  <input onChange={handleInput} type="text" name="" id='username' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                </div>
                <button onClick={handleSubmit} id='login' className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                  <p className="w-full text-white font-bold">Submit</p>
                </button>
              </div>
          </div>

          <Footer />
      </div>
    )
  } else {
    return (
      <div className=''>
          <Header user={user} role={role} />

          {error && (<Error message={error} changeMessage={setError} />)}
          {warning && (<Warning message={warning} changeMessage={setWarning} />)}
          {success && (<Success message={success} changeMessage={setSuccess} />)}

          <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
              <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Forgot password</p>

              <div className="md:w-1/2 mx-auto mt-10">
                <div className="text-left">
                  <p className="ml-6 mb-1">Enter a new password</p>
                  <input onChange={handlePassword} type="password" name="" id='password' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                </div>
                <div className="text-left mt-5">
                  <p className="ml-6 mb-1">Confirm the new password</p>
                  <input onChange={handlePasswordConfirm} type="password" name="" id='confirmpassword' className="border-accent border rounded-full flex flex-row px-6 w-full py-2" />
                </div>
                <button onClick={handleSubmitReset} id='login' className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                  <p className="w-full text-white font-bold">Submit</p>
                </button>
              </div>
          </div>

          <Footer />
      </div>
    )
  }
}

export default ForgotPassword