module.exports = {
    heading: "Pricing",
    sub_heading: "Whether you’re starting or you’re working on top games, we’ve got a plan for you.",
    
    display_monthly_yearly_choice: true,

    features: [
        {
            category: "e-commerce",
            subcategory: [
                {
                    name: "Stores"
                },
                {
                    name: "Products per store"
                },
                {
                    name: "Orders"
                },
            ]
        },
        {
            category: "platform",
            subcategory: [
                {
                    name: "Ratings"
                },
                {
                    name: "Product Reviews"
                },
                {
                    name: "Email notifications"
                },
            ]
        },
        {
            category: "marketing",
            subcategory: [
                {
                    name: "Analytics dashboard"
                },
                {
                    name: "File size limit"
                },
                {
                    name: "Coupon codes"
                },
                {
                    name: "Email marketing"
                },
            ]
        },
        {
            category: "payments",
            subcategory: [
                {
                    name: "Transaction fees"
                },
                {
                    name: "Payout cycle"
                },
                {
                    name: "Payout threshold"
                },
            ]
        },
        {
            category: "support",
            subcategory: [
                {
                    name: "Customer support"
                },
            ]
        }
    ],

    pricing_plans: [
        {
            name: "Starter",
            price: "FREE",

            feature_answers: {
                ecommerce: [
                    {
                        name: "1"
                    },
                    {
                        name: "5"
                    },
                    {
                        name: "∞"
                    },
                ],
                platform: [
                    {
                        name: true
                    },
                    {
                        name: true
                    },
                    {
                        name: true
                    },
                ],
                marketing: [
                    {
                        name: "basic"
                    },
                    {
                        name: "10MB"
                    },
                    {
                        name: false
                    },
                    {
                        name: false
                    },
                ],
                payments: [
                    {
                        name: "30%"
                    },
                    {
                        name: "monthly"
                    },
                    {
                        name: "25$"
                    },
                ],
                support: [
                    {
                        name: "standard"
                    },
                ],
            }
        },
        // {
        //     name: "Basic",
        //     price: "4.99",

        //     feature_answers: {
        //         ecommerce: [
        //             {
        //                 name: "2"
        //             },
        //             {
        //                 name: "10"
        //             },
        //             {
        //                 name: "∞"
        //             },
        //         ],
        //         platform: [
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //         ],
        //         marketing: [
        //             {
        //                 name: "basic"
        //             },
        //             {
        //                 name: "25MB"
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: false
        //             },
        //         ],
        //         payments: [
        //             {
        //                 name: "30%"
        //             },
        //             {
        //                 name: "weekly"
        //             },
        //             {
        //                 name: "25$"
        //             },
        //         ],
        //         support: [
        //             {
        //                 name: "standard"
        //             },
        //         ],
        //     }
        // },
        // {
        //     name: "Standard",
        //     price: "14.99",

        //     feature_answers: {
        //         ecommerce: [
        //             {
        //                 name: "3"
        //             },
        //             {
        //                 name: "30"
        //             },
        //             {
        //                 name: "∞"
        //             },
        //         ],
        //         platform: [
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //         ],
        //         marketing: [
        //             {
        //                 name: "advanced"
        //             },
        //             {
        //                 name: "50MB"
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //         ],
        //         payments: [
        //             {
        //                 name: "20%"
        //             },
        //             {
        //                 name: "on request"
        //             },
        //             {
        //                 name: "10$"
        //             },
        //         ],
        //         support: [
        //             {
        //                 name: "fast"
        //             },
        //         ],
        //     }
        // },
        // {
        //     name: "Pro",
        //     price: "34.99",

        //     feature_answers: {
        //         ecommerce: [
        //             {
        //                 name: "∞"
        //             },
        //             {
        //                 name: "∞"
        //             },
        //             {
        //                 name: "∞"
        //             },
        //         ],
        //         platform: [
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //         ],
        //         marketing: [
        //             {
        //                 name: "advanced"
        //             },
        //             {
        //                 name: "250MB"
        //             },
        //             {
        //                 name: true
        //             },
        //             {
        //                 name: true
        //             },
        //         ],
        //         payments: [
        //             {
        //                 name: "15%"
        //             },
        //             {
        //                 name: "on request"
        //             },
        //             {
        //                 name: "10$"
        //             },
        //         ],
        //         support: [
        //             {
        //                 name: "premium"
        //             },
        //         ],
        //     }
        // },
    ]
}