import React, { useEffect, useState } from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Cookies from 'universal-cookie'


import Header from '../components/Header'
import Footer from '../components/Footer'
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import Error from '../components/states/Error'
import Success from '../components/states/Success'
import Warning from '../components/states/Warning'
import scrollToTop from '../hooks/scrollToTop';

function Register() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordIsStrong, setPasswordIsStrong] = useState("")
  const [passwordIsStrongShow, setPasswordIsStrongShow] = useState(false)
  const [tos, setTos] = useState(false)
  const [loginOrRegister, setLoginOrRegister] = useState(false)

  // States
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [warning, setWarning] = useState("")
  const [loading, setLoading] = useState(false)
  
  const handleUsername = (e) => {
    setUsername(e.target.value)
  }
  
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(sha256(e.target.value))
    if(!validator.isStrongPassword(e.target.value, {minSymbols: 0})){
      if(e.target.value.length < 8){
        setPasswordIsStrong("The password should be minimum 8 characters long.")
      } else if(e.target.value.toUpperCase() === e.target.value){
        setPasswordIsStrong("The password should be contain a minimum of 1 lowercase character.")
      } else if(e.target.value.toLowerCase() === e.target.value){
        setPasswordIsStrong("The password should be contain a minimum of 1 uppercase character.")
      } else {
        setPasswordIsStrong("The password should be contain a minimum of 1 number.")
      }
    } else {
      setPasswordIsStrong("")
      setPasswordIsStrongShow(false)
    }
  }

  const handleTOS = () => {
    if(tos){ 
      setTos(false)
    } else {
      setTos(true)
    }
  }
  
  const checkInput = () => {
    if(username.length < 3){
      return 1
    }

    if(email.length === 0 || !validator.isEmail(email)){
      return 2
    }

    if(password.length === 0){
      return 3
    }

    if(!tos){
      return 4
    }

    if(passwordIsStrong !== ""){
      return 5
    }

    return true
  }

  const handleRegister = () => {
    const validateInputs = checkInput()

    if(validateInputs === true){
      axios.post(AUTHENTICATION_URL + "register", {
        username: username,
        email: email,
        password: password,
        referer: newCookies.get("ref")
      })
        .then((response) => {
          if(response.data.success){
            // newCookies.set("user", response.data.token, { path: '/' })
            // setLoginOrRegister(true)
            // getUser()

            setSuccess("Create a ticket to be accepted with your username of the website in the discord server.")
          } else {
            setError(response.data.message)
            scrollToTop()
          }
        })
    } else {
      if(validateInputs === 1){        
        setError("An username longer then 3 and shorter then 20 characters should be entered.")
      } else if(validateInputs === 2){
        setError("A valid email should be entered.")
      } else if(validateInputs === 3){
        setError("A password should be entered.")
      } else if(validateInputs === 4){
        setError("You should agree to our Terms of Service to start using our service.")
      } else {
        setError("")
        setPasswordIsStrongShow(true)
      }
      scrollToTop()
    }
  }

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")


  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)

            navigate("/dashboard")
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
      <Header user={user} role={role} loginOrRegister={loginOrRegister} />

        {error && (<Error message={error} changeMessage={setError} />)}
        {warning && (<Warning message={warning} changeMessage={setWarning} />)}
        {success && (<Success message={success} changeMessage={setSuccess} />)}


        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Register</p>

            <div className="md:w-1/2 mx-auto mt-10">
              <div className="text-left">
                <p className="ml-6 mb-1">Username</p>
                <input onChange={handleUsername} type="text" name="" id="username" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" required />
              </div>

              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Email</p>
                <input onChange={handleEmail} type="email" name="" id="email" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" required />
              </div>
              
              <div className="text-left mt-5">
                <p className="ml-6 mb-1">Password</p>
                <input onChange={handlePassword} type="password" name="" id="password" className="border-accent border rounded-full flex flex-row px-6 w-full py-2" required />
              </div>

              <p className="text-sm text-red-500 w-full text-left ml-6">{passwordIsStrongShow && passwordIsStrong}</p>
              
              <div className="text-left mt-8">
                <div className="flex flex-row">
                  <table>
                    <tbody>
                      <tr>
                        <td className='align-middle'>
                          <input type="checkbox" onChange={handleTOS} name="" id='accept-tos' className='ml-6 mb-1 accent-accent scale-150' required />
                        </td>
                        <td className='align-middle'>
                          <p className="text-base ml-3">I agree with the <span className='text-accent underline underline-offset-2'><a href="/terms-of-service">Terms of Service</a></span>.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <button onClick={handleRegister} id="register" className="border-accent border rounded-full flex flex-row px-6 w-full py-2 text-center bg-accent mt-5">
                <p className="w-full text-white font-bold">Let's start today</p>
              </button>

              <a href="/login">
                <p className="text-left pt-3 ml-2 text-accent underline underline-offset-2">Already have an account?</p>
              </a>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Register