import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { heading, sub_heading, resource_items } from '../configs/Resources_Page.config'
import FaqItem from '../components/Resources/FaqItem'
import { AUTHENTICATION_URL } from '../configs/api/api.config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'


function Features() {
  const newCookies = new Cookies()
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className=''>
      <Header user={user} role={role} />



        <div className="mx-auto pt-20 md:pt-32 lg:pt-52 pb-48 text-center container">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">{heading}</p>
            <p className="text-lg mt-4 w-3/4 md:w-1/2 lg:w-1/3 mx-auto">{sub_heading}</p>
        
            <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 mt-20">
              {resource_items.map((item, key) => {
                  return(
                      <FaqItem item={item} key={key} />
                  )
              })}
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Features