import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import { useState } from 'react';
import axios from 'axios';
import { AUTHENTICATION_URL, SEARCH_URL } from '../configs/api/api.config';
import Item from '../components/search/Item';
import Cookies from 'universal-cookie';
import scrollToTop from '../hooks/scrollToTop';


function App() {
  const newCookies = new Cookies()
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const [user, setUser] = useState(0)
  const [role, setRole] = useState("login")

  const [keywords, setKeywords] = useState("")
  const [sortBy, setSortBy] = useState("")
  const [page, setPage] = useState(1)
  const [foundProducts, setFoundProducts] = useState([])
  const [noProductsFound, setNoProductsFound] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)

  useEffect(() => {    
    setPage(searchParams.get("page"))
  }, [])
  
  useEffect(() => {
    setKeywords(searchParams.get("keywords"))
    setSortBy(searchParams.get("sort-by"))

    axios.get(SEARCH_URL + "?keywords=" + searchParams.get("keywords") + "&sort-by=" + searchParams.get("sortBy") + "&page=" + searchParams.get("page"))
      .then((response) => {
        if(response.data.success){
          setFoundProducts(response.data.data)
          setNoProductsFound(false)

          if(response.data.data.length < 20){
            setIsLastPage(true)
          } else {
            setIsLastPage(false)
          }
        } else if(response.data.message === "No products where found."){
          setNoProductsFound(true)
        }
      })
  }, [searchParams])

  useEffect(() => {
    setSearchParams({"keywords": keywords, "sort-by": sortBy, "page": page})
  }, [page])

  // useEffect(() => {
  //   axios.get(SEARCH_URL + "?keywords=" + keywords + "&sort-by=" + sortBy + "&page=" + page)
  //     .then((response) => {
  //       console.log(response)
  //       if(response.data.success){
  //         setFoundProducts(response.data.data)
  //       }
  //     })
  // }, [keywords, sortBy, page])
  function getCookie(){
    if(newCookies.get('user')){
      return newCookies.get('user')
    }
  }  

  function getUser(){
    const user = getCookie()

    if(user){
      axios.get(AUTHENTICATION_URL, {
        headers: {
          'x-access-token': user
        }
      })
        .then((response) => {
          console.log(response)
          if(response.data.success){
            setUser(response.data.user_id)
            setRole(response.data.role)
          } 
        })
    }      
  }


  function handleSearchInput(e){
    setSearchParams({"keywords": e.target.value, "sort-by": sortBy, "page": page})
  }

  function handleSearchEnter(e){
    if(e.key === "Enter"){
      setSearchParams({"keywords": keywords, "sort-by": sortBy, "page": page})
    }
  }

  function handleSearchClick(){
    setSearchParams({"keywords": keywords, "sort-by": sortBy, "page": page})
  }

  function handleNextPage(){
    setPage(parseInt(page + 1))
    scrollToTop()
  }

  function handlePreviousPage(){
    if(page > 1){ 
      setPage(page - 1)
      scrollToTop()
    }
  }
  

  return (
    <div className='min-w-screen min-h-screen'>
      <Header user={user} role={role} />


      <div className="mx-auto pt-20 md:pt-32 lg:pt-52 text-center container">
        <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">Searching for <span className="text-accent underline underline-offset-2">{keywords}</span></p>
        <p className="text-lg mt-4 w-full md:w-1/2 lg:w-2/5 mx-auto">Look for the best fitting item for your upcoming project.</p>

        {/* Search + Sorting */}
        <div className="flex flex-row md:w-3/4 lg:w-1/2 mx-auto">
          {/* Sort by */}
          <div className=" border-accent border rounded-full px-6 py-2 mr-2 mt-16 capitalize">
            <p className="" dangerouslySetInnerHTML={{__html: sortBy}} />
          </div>

          {/* Search bar */}
          <div className=" border-accent border rounded-full px-6 py-2 mt-16 w-5/6">
            <table className="w-full">
              <tbody className="w-full">
                <tr className="w-full flex flex-row justify-between">
                  <td className="align-middle w-full">
                    <input type="text" onChange={handleSearchInput} onKeyDown={handleSearchEnter} name="" className="focus:outline-0 w-full" />
                  </td>
                  <td className="align-middle">
                    <button className="" onClick={handleSearchClick}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search text-accent" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Sorting Menu */}
        {/* <div className="md:w-3/4 lg:w-1/2 mx-auto mt-1">
          <div className="w-1/4 border-accent bg-accent/10 border rounded-full px-6 py-2">
            
          </div>
        </div> */}

        {/* Result of search */}
        <div className="md:w-3/4 lg:w-1/2 mx-auto mt-20">
          {foundProducts && foundProducts.map((item, key) => {
            return (
              <Item item={item} key={key} />
            )
          })}
        </div>

        {/* No products found */}
        {noProductsFound && (
          <div className="md:w-3/4 lg:w-1/2 mx-auto">
            <p className="font-semibold ">No more products where found...</p>
          </div>
        )}

        <div className="md:w-3/4 lg:w-1/2 mx-auto mt-6">
          <table className="w-full">
            <tbody className="w-full">
              <tr className="w-full">
                <td className="w-1/3">
                  {page > 1 && (
                    <button onClick={handlePreviousPage} className="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-accent bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                      <svg aria-hidden="true" className="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                      Previous
                    </button>
                  )}
                </td>

                <td className='w-1/3'>
                  <p className="py-2 px-4 mr-3 text-sm font-medium text-accent bg-white rounded-lg border border-gray-300">
                    {page}
                  </p>
                </td>

                <td className="w-1/3">
                  {!isLastPage && (
                    <button onClick={handleNextPage} className="inline-flex items-center py-2 px-4 text-sm font-medium text-accent bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                      Next
                      <svg aria-hidden="true" className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default App