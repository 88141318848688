import React from 'react'
import { features_heading, features_subheading, features } from '../../configs/home/Features.config'

function Features() {
  return (
    <div className='mt-40'>
        {/* Wave */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f1f5f9" fillOpacity="1" d="M0,192L60,181.3C120,171,240,149,360,170.7C480,192,600,256,720,245.3C840,235,960,149,1080,128C1200,107,1320,149,1380,170.7L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>

        {/* Features */}
        <div className="bg-slate-100">
            <div className="container mx-auto pt-40">
              <p className="text-2xl font-extrabold text-center">{features_heading}</p>
              <p className="text-center w-3/4 md:w-1/2 lg:w-2/5 mx-auto py-3">{features_subheading}</p>
            
              <div className="pt-10 pb-44 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {features.map((feature, key) => {
                  return (
                    <div key={key} className="bg-white rounded-xl">
                      <div className="bg-accent/20 h-full rounded-xl">
                        <div className="ml-3 pt-2">
                          <table>
                            <tbody>
                              <tr>
                                <td className='align-middle'>
                                  <svg className='mr-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" dangerouslySetInnerHTML={{__html: feature.svg_icon}}></svg>
                                </td>
                                <td className='align-middle'>                                
                                  <p className="text-xl font-semibold">{feature.feature_heading}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          
                        </div>
                        
                        <p className="ml-3 pb-6 pr-2">{feature.feature_description}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
        </div>    

        {/* End wave */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#f1f5f9" fillOpacity="1" d="M0,160L48,181.3C96,203,192,245,288,245.3C384,245,480,203,576,165.3C672,128,768,96,864,96C960,96,1056,128,1152,149.3C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
        </svg>
    </div>
  )
}

export default Features